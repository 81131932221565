<!-- Formaulario Horizontal -->
<ng-container *ngFor="let sign of horizontalSignalingTaskActivities; index as indexOfSign">
  <accordion [isAnimated]="true" [closeOthers]="true">
    <accordion-group [isOpen]="sign?.isAccOpen">
      <div class="row mx-0 w-100" style="cursor: auto" accordion-heading>
        <div class="col sign-container">

          <!-- SVG da Sinalização -->
          <div class="svg-collapse" (click)="openAccordionGroup(sign)">
            <img
              [src]="sign.horizontalCode ? (sign.horizontalCode| enumSvg: 'Signaling': signalingTypeEnum.Horizontal) : 'assets/icons/h-task-icon.svg'"
              alt="" height="48px" width="48px">
          </div>
          <!-- SVG da Sinalização -->


          <!-- Nome da Sinalização -->
          <div class="col mt-2 cursor-pointer" *ngIf="!sign?.isAccOpen" (click)="openAccordionGroup(sign)">
            <div class="ml-2">
              <h4 class="item-title">Sinalização selecionada</h4>
              <p>{{sign.horizontalCode ? (sign?.horizontalCode|enumText: 'Signaling') : 'Selecione a Sinalização'}}</p>
            </div>
          </div>
          <!-- Nome da Sinalização -->


          <!-- Formulário da Sinalização -->
          <div class="col mt-2" *ngIf="sign?.isAccOpen">
            <div class="content-contractual">
              <form #horizontalForm="ngForm">
                <div class="form-group" *ngIf="isStock || (!isStock && !stockEntries?.length)">
                  <label for="contractHorizontalItemId">Selecione o item do Contrato</label>
                  <select name="contractHorizontalItemId" class="form-control" required
                    placeholder="Selecione o item do contrato" [(ngModel)]="sign.contractHorizontalItemId"
                    (change)="sign.contractHorizontalItemId = $event.target.value;setContractItem(sign); emitValidation()">
                    <option value="" hidden>Selecione...</option>
                    <option *ngFor="let option of horizontalItems" [value]="option.id">{{option?.name}}</option>
                  </select>
                </div>
                <div class="row mx-0">
                  <div class="col-2 px-0">
                    <div class="form-group">
                      <label for="quantity">Quantidade</label>
                      <input name="quantity" class="form-control" placeholder="0" [(ngModel)]="sign.quantity" min="0"
                        oninput="if(this.value < 0) this.value = 0" required
                        (change)="emitValidation(); remakeActivitieList(sign)">
                    </div>
                  </div>
                  <!-- Sinalização Com Modal -->
                  <ng-container>
                    <div class="d-flex align-items-center justify-content-between col-10">
                      <div class="d-flex align-items-center w-100 ml-3" style="gap:32px">
                        <img [src]="sign.horizontalCode| enumSvg: 'Signaling': signalingTypeEnum.Horizontal"
                          style="width: 40px; height: 40px;">

                        <span style="color: var(--gray-base);">
                          {{sign.horizontalCode
                          ? (sign.horizontalCode|enumText:'Signaling')
                          : 'Selecione a sinalização a ser cadastrada.'}}
                        </span>
                      </div>

                      <button class="btn-popup button-sign p-0">
                        <div class="d-flex align-items-center justify-content-center flex-column"
                          style="width: 123px; height: 46px;" (click)="currentItem = sign; openModalXL(selectSign);">
                          <p class="m-0">SELECIONAR</p>
                        </div>
                      </button>

                    </div>
                  </ng-container>
                  <!-- Sinalização Com Modal -->
                </div>

                <div class="d-flex align-items-center justify-content-between mb-3 dimension-container">
                  <h3>
                    Lista de Sinalizações do Item de Projeto
                  </h3>
                  <button class="btn-popup button-sign p-0">
                    <div class="d-flex align-items-center justify-content-center flex-column"
                      style="width: 123px; height: 46px;" (click)="currentItem = sign; openModalXL(listActivieSigns);">
                      <p class="m-0">ABRIR LISTA</p>
                    </div>
                  </button>
                </div>

                <div class="col d-flex flex-column px-4 dimension-container mb-2">
                  <p class="text-uppercase text-muted mb-2">Dimensão Unitária da Sinalização</p>
                  <div class="row mx-0 d-flex align-items-center">
                    <app-dimension-information style="margin-top: -16px;" class="mb-2 w-100"
                      [signType]="signalingTypeEnum.Horizontal" [dimensionInformationState]="{
                        dimensionType: sign.dimensionType,
                        dimension: sign.dimension,
                        dimensionX: sign.dimensionX,
                        dimensionY: sign.dimensionY,
                        quantity: sign.units,
                        dash: sign.dash ?? 0,
                        spacing: sign.spacing ?? 0
                      }" [displayDirection]="'row'" [idSign]="sign.id" [switchId]="indexOfSign"
                      [signCode]="sign.signCode"
                      (dimensionInformationEmmitter)="handleDimensionInformation($event, sign)">
                    </app-dimension-information>
                  </div>
                </div>
                <div class="col d-flex flex-column px-4 dimension-container">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" [name]="'enableRetroreflection'+indexOfSign"
                      [id]="'enableRetroreflection'+indexOfSign"
                      (change)="!sign['retroSwitch'] ? sign['retroSwitch'] = true : sign['retroSwitch'] = !sign['retroSwitch']; emitValidation()"
                      [checked]="sign.maximumRetroreflectionValue || sign.minimumRetroreflectionValue">
                    <label class="custom-control-label"
                      [for]="'enableRetroreflection'+indexOfSign">Retrorrefletorização</label>
                  </div>
                  <div class="row mx-0 my-2"
                    *ngIf="sign['retroSwitch'] || sign.maximumRetroreflectionValue || sign.minimumRetroreflectionValue">
                    <div class="form-group col pl-0">
                      <label for="maximum">Valor Desejado (mcd Lux/m²)</label>
                      <input type="number" (change)="emitValidation()" class="form-control"
                        placeholder="Insira o valor desejado" [(ngModel)]="sign.maximumRetroreflectionValue"
                        name="maxValue">
                    </div>
                    <div class="form-group col pr-0">
                      <label for="minimum">Valor Mínimo (mcd Lux/m²)</label>
                      <input type="number" (change)="emitValidation()" class="form-control"
                        placeholder="Insira o valor desejado" [(ngModel)]="sign.minimumRetroreflectionValue"
                        name="minValue">
                    </div>
                  </div>
                </div>
                <div class="row mx-0 my-2">
                  <div class="col-4 px-0">
                    <div class="px-0 form-group">
                      <label for="legend">Determine o tempo de garantia*</label>
                      <div class="row d-flex align-items-center justify-content-between mx-0">
                        <div class="position-relative w-100">
                          <input bsDatepicker class="form-control" #wDate
                            [ngModel]="dateService.TransformDate(sign,'get')" name="warranty"
                            [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                            (bsValueChange)="dateService.TransformDate(sign,'set',wDate.value);setWarranty($event, sign); emitValidation()">
                          <i class="fa-solid fa-calendar position-absolute" (click)="dp.show()"
                            style="right: 5%; top: 35%; cursor:text"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="align-items-center d-flex col px-0">
                    <div *ngIf="true" class="d-flex justify-content-between flex-column col-4">
                      <a style="font-size: 20px; color:var(--greenmix-light); pointer-events: none;"
                        [ngClass]="{'text-danger': sign['active'] > 1}">
                        {{!sign['warrantyTextCurrent'] || sign['warrantyTextCurrent'] == 0 ? 'Sem período':
                        sign['warrantyTextCurrent']}}</a>
                      <a [ngClass]="{'text-danger': sign['active'] > 1}"
                        style="color:var(--red-base); font-weight: 500; font-size: 14px; color:var(--greenmix-light); pointer-events: none; white-space: nowrap;">
                        {{sign.warranty ? (sign.warranty | date:'dd/MM/yyyy') : 'Escolha a data de expiração'}}</a>
                    </div>
                    <div class="col px-0">
                      <progressbar *ngIf="sign['active'] == 1" class="mx-4"
                        style="border-radius: 8px; height: 8px; width: 100%;" [value]="(sign['progress'])*100"
                        type="success">
                      </progressbar>
                      <progressbar *ngIf="sign['active'] > 1" class="mx-4"
                        style="border-radius: 8px; height: 8px; width: 100%;" [value]="(sign['progress'])*100"
                        type="danger">
                      </progressbar>
                    </div>
                    <div class="d-flex justify-content-between flex-column align-items-end col px-0">
                      <a *ngIf="sign['warrantyTextCurrent'] !== 0"
                        style="font-size: 20px; color:var(--gray-light); pointer-events: none;">{{sign.warranty
                        ? (sign.warranty | date:'dd/MM/yyyy') : 'Sem Período'}}</a>
                      <a *ngIf="sign['warrantyTextCurrent'] == 0"
                        style="color:var(--red-base); font-weight: 500; font-size: 16px; pointer-events: none;"><i
                          class="fa-solid fa-triangle-exclamation"
                          style="margin-right: 8px; font-size: 20px;"></i>Garantia
                        Expirada</a>
                      <h4 class="mt-2" style="color:var(--gray-light)" *ngIf="sign['warrantyTextCurrent'] !== 0">
                        Sem
                        última edição</h4>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row  px-4 justify-content-between align-items-center  my-2 py-2">
                <div class="col px-0">
                  <div>
                    <label for="roadSignMarking" class="text-muted">Material Aplicado</label>
                    <p>{{sign.contractHorizontalItem ? sign.contractHorizontalItem?.roadSignMarkingPaint.name :
                      'Não Informado'}}</p>
                  </div>
                </div>
              </div>
              <div class="row  px-4 justify-content-between align-items-center  my-2 py-2">
                <div class="col px-0">
                  <div>
                    <label for="signQuant" class="text-muted">Qnt. de Sinalizações</label>
                    <p>{{sign.quantity || 'Não informada'}}</p>
                  </div>
                </div>
                <div class="col px-0">
                  <label for="dimension" class="text-muted">Dimensão Total (m²)</label>
                  <p>{{sign.dimension}} m²</p>
                </div>
                <div class="col px-0">
                  <label for="signUnit" class="text-muted">Vr. Und. da Sinalização</label>
                  <p class="text-success">{{sign.contractHorizontalItem ? (sign.dimension *
                    sign.contractHorizontalItem.unitValue | currency:'BRL':'R$') : ('R$0,00')}}</p>
                </div>
                <div class="col px-0">
                  <label for="itemValue" class="text-muted">Valor do Item</label>
                  <p>{{ sign.contractHorizontalItem ? ((sign.contractHorizontalItem.unitValue |
                    currency:'BRL':'R$')+'/'+(sign.contractHorizontalItem.unit)) : ('R$0,00/--')}}</p>
                </div>
                <div class="col px-0">
                  <label for="totalValue" class="text-muted">Valor Total</label>
                  <p class="text-success">{{sign.contractHorizontalItem ? (sign.quantity * sign.dimension *
                    sign.contractHorizontalItem.unitValue | currency:'BRL':'R$') : ('R$0,00')}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Formulário da Sinalização -->


          <!-- Collapse -->
          <div class="collapse-button" (click)="openAccordionGroup(sign)">
            <i class="fa-regular fa-lg" [ngClass]="sign?.isAccOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
          </div>
          <!-- Collapse -->

        </div>

        <div class="col-auto px-0 pl-4">
          <button class="btn btn-icon btn-icon-alt" (click)="removeActivity(indexOfSign)">
            <i class="fa-regular fa-xmark fa-lg text-danger"></i>
          </button>
        </div>

      </div>
    </accordion-group>
  </accordion>
</ng-container>


<!-- Template Modal Selecionar Sinalização Filtro-->
<ng-template #selectSign>
  <app-sign-list-modal [signType]="signalingTypeEnum.Horizontal" [signModalSelect]="this.currentItem.horizontalCode"
    (shareSelectSign)="selectSignModal($event)" (shareCloseModal)="closeModal()">
  </app-sign-list-modal>
</ng-template>
<!-- Template Modal Selecionar Sinalização Filtro-->

<ng-template #listActivieSigns>

  <app-table-map-activitie-project [signType]="signalingTypeEnum.Horizontal" [currentActivitieSign]="currentItem"
    (emitActivitieSign)="receiveActivitie($event)" (emitCloseModal)="closeModal()">
  </app-table-map-activitie-project>

</ng-template>
