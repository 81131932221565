import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MapService } from 'src/app/services/map.service';
import { SignalingTypeEnum, VerticalSignalingEnum } from 'src/enumerators';
import * as Leaflet from 'leaflet';

@Component({
  selector: 'app-table-map-activitie-project',
  templateUrl: './table-map-activitie-project.component.html',
  styleUrls: ['./table-map-activitie-project.component.css']
})
export class TableMapActivitieProjectComponent implements OnInit {

  @Input() currentActivitieSign: any;
  @Input() signType;
  @Output() emitActivitieSign = new EventEmitter<any>;
  @Output() emitCloseModal = new EventEmitter<any>;

  map: Leaflet.Map;
  layer: Leaflet.LayerGroup = new Leaflet.LayerGroup();

  latlngForm = ['', ''];

  signalingTypeEnum = SignalingTypeEnum;
  verticalSignEnum = VerticalSignalingEnum;
  markerMap: Map<string, Leaflet.Marker> = new Map();

  constructor(
    private _mapService: MapService,
    private _modalService: BsModalService,
  ) {

  }

  ngOnInit(): void {
    if (this.signType == SignalingTypeEnum.Device && !this.currentActivitieSign.activitiesList.length) {
      for (let index = 0; index < 2; index++) {
        this.currentActivitieSign.activitiesList?.push({ signCode: this.currentActivitieSign.deviceCode, latitude: undefined, longitude: undefined, latlngForm: '' })
      }
    }
    this.initMap();
  }

  initMap() {
    setTimeout(() => {
      this.map = this._mapService.initMap('activitie-sign-map');
      this._mapService.initLayer(this.map, this.layer);
      let coordinates = [];
      if (this.signType != SignalingTypeEnum.Device) {
        this.currentActivitieSign?.activitiesList?.forEach((element, index) => {
          if (element?.latitude && element?.longitude) {
            this.addMarker(element.signCode, { lat: element?.latitude, lng: element?.longitude }, index);
            coordinates.push([element?.latitude, element?.longitude]);
            element.latlngForm = `${element?.latitude}, ${element?.longitude}`;
          }
        });
        if (this.currentActivitieSign?.activitiesList?.length && coordinates?.length) this.centralizeMap();
      } else {
        this.currentActivitieSign?.locations.forEach((element, index) => {
          this.addMarker(this.currentActivitieSign.deviceCode, { lat: element?.latitude, lng: element?.longitude }, index);
          this.currentActivitieSign.activitiesList[index].latlngForm = `${element?.latitude}, ${element?.longitude}`;
          coordinates.push([element?.latitude, element?.longitude]);
        });
        if (coordinates?.length) this.centralizeMap();

      }
    }, 500);
  }

  changeLatLngForm(element, type, value, index) {
    const latlng = this.separateLatLng(value);

    if (type == SignalingTypeEnum.Device) {
      if (this.currentActivitieSign.locations[index])
        this.currentActivitieSign.locations[index] = { latitude: latlng.lat, longitude: latlng.lng };
      else
        this.currentActivitieSign.locations.push({ latitude: latlng.lat, longitude: latlng.lng });
    }

    else {
      element.latitude = latlng.lat;
      element.longitude = latlng.lng;
    }
    if (latlng.lat && latlng.lng) {
      this.addMarker(element.signCode, { lat: latlng.lat, lng: latlng.lng }, index);
      this.centralizeMap();
      this.emitActivitieSign.emit(this.currentActivitieSign);
    }
  }

  // Centraliza o mapa na sinalização escolhida na lista
  centralizeMap(): void {
    let coordinates = [];

    // Extraia as coordenadas dos marcadores
    this.markerMap?.forEach(marker => {
      if (marker && marker.getLatLng) {
        coordinates.push(marker.getLatLng());
      }
    });

    // Verifique se há coordenadas para criar o bounds
    if (coordinates.length > 0) {
      const bounds = Leaflet.latLngBounds(coordinates);
      this.map.fitBounds(bounds); // Ajusta o mapa ao bounds
    }
  }

  // Aceita Lat/Lng - Lat / Lng - Lat,Lng - Lat , Lng - Lat Lng;
  separateLatLng(coordStr) {
    // Remove qualquer vírgula
    coordStr = coordStr.replace(',', ' ');
    // Remove qualquer barra
    coordStr = coordStr.replace('/', ' ');
    // Separa pelos espaços
    const [lat, lng] = coordStr.trim().split(/\s+/);
    return { lat, lng };
  }

  addMarker(code, latLng, index) {
    let oldMarker = this.markerMap.get(index);
    if (oldMarker) this.layer.removeLayer(oldMarker);

    let marker = this._mapService.addSimpleMarker(code, this.layer, latLng, { draggable: true });
    marker.on('dragend', (e) => {
      const newPos = e.target.getLatLng();
      if (this.currentActivitieSign['type'] == SignalingTypeEnum.Device) {
        this.currentActivitieSign.locations[index].latitude = newPos.lat.toFixed(6);
        this.currentActivitieSign.locations[index].longitude = newPos.lng.toFixed(6);
        this.currentActivitieSign.activitiesList[index].latlngForm = `${newPos.lat.toFixed(6)}, ${newPos.lng.toFixed(6)}`;
      }
      else {
        this.currentActivitieSign.activitiesList[index].latitude = newPos.lat.toFixed(6);
        this.currentActivitieSign.activitiesList[index].longitude = newPos.lng.toFixed(6);
        this.currentActivitieSign.activitiesList[index].latlngForm = `${newPos.lat.toFixed(6)}, ${newPos.lng.toFixed(6)}`;
      }
    })

    this.markerMap.set(index, marker);
  }

  closeModal() {
    this.emitCloseModal.emit(true);
  }

  enableMarkerPlacement(item, index?) {
    // Salva o índice do botão clicado

    // Escuta o clique no mapa
    this.map.once('click', (event: any) => {
      const { lat, lng } = event.latlng;

      // Adiciona o marcador no mapa
      this.addMarker(item.signCode, { lat, lng }, index);

      if (item['type'] == SignalingTypeEnum.Device) {
        item.locations[index].latitude = lat.toFixed(6);
        item.locations[index].longitude = lng.toFixed(6);
        item.activitiesList[index].latlngForm = `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
      }
      else {
        item.latitude = lat.toFixed(6);
        item.longitude = lng.toFixed(6);
        item.latlngForm = `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
      }
    });
  }
}
