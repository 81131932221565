import { Component, Input, OnInit } from '@angular/core';
import { DateService } from 'src/app/services/date.service';
import {
  ComplementInformationEnum,
  ComplementInformationSignEnum,
  DimensionInformationEnum,
  SignalingTypeEnum,
  VerticalGroupTypeEnum,
  VerticalSignalingEnum,
  VerticalSignalizationTypeEnum
} from 'src/enumerators';
import { Contract, ProjectDeviceSignaling, ProjectHorizontalSignaling } from 'src/models';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})
export class ProjectCardComponent implements OnInit {

  @Input() list: any[] = []; // lista de sinalizações HVD ou Suporte
  @Input() type: SignalingTypeEnum | VerticalGroupTypeEnum; // Tipo do item para manipular interação com mapa/arq-mestre
  @Input() contract: Contract; // Contrato

  // Enums
  verticalGroupTypeEnum = VerticalGroupTypeEnum;
  signTypeEnum = SignalingTypeEnum;
  verticalSignTypeEnum = VerticalSignalizationTypeEnum;
  verticalSignEnum = VerticalSignalingEnum;
  dimensionTypeEnum = DimensionInformationEnum;
  complementInformationTypeEnum = ComplementInformationEnum;

  constructor(
    public dateService: DateService,
  ) { }

  ngOnInit(): void {
    if (this.type == SignalingTypeEnum.Device) {
      this.list.forEach((item: ProjectDeviceSignaling) => {
        item['signCode'] = item.deviceCode;
        item['contractSignItem'] = item.contractDeviceItem;
        item['itemContractNumber'] = this.setItemContractNumber(this.contract, this.type, item.contractDeviceItemId);
        item['isAccOpen'] = false;
        this.setWarranty(new Date(item.warranty), item);
      });

    } else if (this.type == SignalingTypeEnum.Horizontal) {
      this.list.forEach((item: ProjectHorizontalSignaling) => {
        item['signCode'] = item.horizontalCode;
        item['contractSignItem'] = item.contractHorizontalItem;
        item['itemContractNumber'] = this.setItemContractNumber(this.contract, this.type, item.contractHorizontalItemId);
        item['isAccOpen'] = false;
        this.setWarranty(new Date(item.warranty), item);
      });

    } else {
      this.list.forEach((item) => {
        item['contractSignItem'] = item.contractVerticalItem;
        if (this.type == SignalingTypeEnum.Vertical) {
          item['signCode'] = item.verticalCode;
          this.setWarranty(new Date(item.warranty), item);
        }
        item['itemContractNumber'] = this.setItemContractNumber(this.contract, this.type, item.contractVerticalItemId);
        item['isAccOpen'] = false;
      });
    }
  }

  openAccordionGroup(item, event: boolean) {
    item.isAccOpen = event;
  }

  // Retorna qual é o tipo da informação complementar sabendo qual é o tipo da placa WARNING/REGULATION
  complementType(sign): ComplementInformationSignEnum {
    if (sign.complementInformationType == ComplementInformationEnum.Additional) {
      if (sign.verticalType == VerticalSignalizationTypeEnum.Warning) {
        return ComplementInformationSignEnum.AdditionalWarning;
      } else {
        return ComplementInformationSignEnum.AdditionalRegulation;
      }
    } else {
      if (sign.verticalType == VerticalSignalizationTypeEnum.Warning) {
        return ComplementInformationSignEnum.IncorporatedWarning;
      } else {
        return ComplementInformationSignEnum.IncorporatedRegulation;
      }
    }
  }

  stringToFloatFixed(string): string {
    const formatedValue = typeof string === 'number' ? string.toString().replace(' ', '') : string.replace(' ', '');
    let coefficient = Math.pow(10, 5);
    let number = Math.floor(parseFloat(formatedValue) * coefficient) / coefficient;

    string = Math.floor(string * coefficient) / coefficient;
    let options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 5,
      useGrouping: false,
    };
    return number.toLocaleString('pt-BR', options);
  }

  // Adiciona o numero do item do contrato ao item do projeto
  setItemContractNumber(contract, type, itemId): string {
    let itemNumber;
    switch (type) {

      case SignalingTypeEnum.Horizontal:
        contract.horizontalGroups.forEach((group, indexGroup) => {
          group.horizontalItems.forEach((item, indexItem) => {
            if (item.id == itemId) {
              itemNumber = `Item ${indexGroup + 1}.${indexItem + 1}`;
            }
          });
        });
        break;

      case SignalingTypeEnum.Device:
        contract.deviceGroups.forEach((group, indexGroup) => {
          group.deviceItems.forEach((item, indexItem) => {
            if (item.id == itemId) {
              itemNumber = `Item ${indexGroup + 1}.${indexItem + 1}`;
            }
          });
        });
        break;

      default:
        contract.verticalGroups.forEach((group, indexGroup) => {
          if (group.groupType == VerticalGroupTypeEnum.RoadSign) {
            group.verticalItems.forEach((item, indexRoad) => {
              if (item.id == itemId) {
                itemNumber = `Item ${indexGroup + 1}.${indexRoad + 1}`;
              }
            });

          } else {
            group.verticalItems.forEach((item, indexSupport) => {
              if (item.id == itemId) {
                itemNumber = `Item ${indexGroup + 1}.${indexSupport + 1}`;

              }
            });
          }
        });
        break;
    }
    return itemNumber;
  }


  // Seta a garantia da sinalização e barra de progresso
  setWarranty(event: Date, sign: any) {
    sign['warrantyTextCurrent'] = null;
    sign['warrantyCurrent'] = null;
    sign['warrantyEnd'] = null;
    sign['progress'] = 0;
    sign['active'] = 1;
    sign.warranty = event;
    const relativeTime: any[] = this.dateService.getRelativeTime(event, new Date());
    sign['warrantyTextCurrent'] = this.dateService.getTextDate(relativeTime);
    this.progressWarranty(event, sign);
  }

  // Função par auxiliar na criação da barra de progresso de garantia
  progressWarranty(warranty, sign: any) {
    let now = new Date();
    sign['warrantyEnd'] = this.dateService.getRelativeTime(warranty, now);
    sign['warrantyCurrent'] = this.dateService.getRelativeTime(warranty, now);

    if (sign['warrantyCurrent'][0] || sign['warrantyEnd'][0]) {
      sign['progress'] = sign['warrantyCurrent'][0] / sign['warrantyEnd'][0];
    } else {
      sign['progress'] = 0;
    }

    if (sign['progress'] <= 0) {
      sign['active'] = 3;
    } else {
      if (
        sign['warrantyCurrent'][1] <= 0 &&
        ((sign['warrantyCurrent'][2] <= 0 && sign['warrantyCurrent'][3] <= 31) ||
          (sign['warrantyCurrent'][2] == 1 && sign['warrantyCurrent'][3] == 0))
      ) {
        sign['active'] = 2;
      } else {
        sign['active'] = 1;
      }
    }
  }


}
