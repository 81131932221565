<ng-container>
  <ng-container *ngIf="itemList.length">
    <div class="scroll-table">
      <table class="table pr-3" style="border: 0">
        <thead>
          <tr>
            <th>Identificador</th>
            <th>{{ itemType == 'vertical' ? 'Código' : 'Tipo' }}</th>
            <th *ngIf="itemType != 'support'">Área (m²)</th>
            <th *ngIf="itemType != 'support'">Largura (m²)</th>
            <th *ngIf="itemType != 'support'">Comprimento (m²)</th>
            <th *ngIf="itemType != 'vertical'">Material</th>
            <th *ngIf="itemType == 'vertical'">Película</th>
            <th *ngIf="itemType == 'vertical'">Substrato</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of itemList; index as i">
            <!-- Primeira linha: visualização dos dados -->
            <tr class="first-combined-row combined-row">
              <td>{{ item.code }}</td>
              <td>{{ item.type }}</td>
              <td *ngIf="itemType != 'support'">{{ item.dimension }}</td>
              <td *ngIf="itemType != 'support'">{{ item.dimensionX }}</td>
              <td *ngIf="itemType != 'support'">{{ item.dimensionY }}</td>
              <td *ngIf="itemType != 'vertical'">{{ item.material }}</td>
              <td *ngIf="itemType == 'vertical'">{{ item.film || '' }}</td>
              <td *ngIf="itemType == 'vertical'">{{ item.substrate || '' }}</td>
            </tr>

            <!-- Segunda linha: inputs para edição dos dados -->
            <tr class="combined-row-inputs">
              <td>{{ 'Sistema - ' + i }}</td>
              <td *ngIf="itemType != 'support'">
                <img
                  [src]="resultList[itemType][i].type | enumSvg: 'Signaling':signTypeUpper"
                  (click)="changeCodeProject(resultList[itemType][i], signCodeModal)"
                  style="width: 48px; height: 48px; object-fit: contain; cursor: pointer"
                />
              </td>
              <td *ngIf="itemType == 'support'">
                <div class="d-flex" style="gap: 8px">
                  <input
                    (change)="emitEvent()"
                    type="text"
                    class="form-control"
                    placeholder="Insira o valor"
                    [(ngModel)]="resultList[itemType][i].type"
                  />
                  <ng-container>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        (change)="resultList[itemType][i].check = !resultList[itemType][i].check"
                        [id]="'existingSupport' + i"
                        [name]="'existingSupport' + i"
                        class="custom-control-input"
                        [checked]="resultList[itemType][i].check"
                      />
                      <label class="custom-control-label" [for]="'existingSupport' + i"> Suporte Existente</label>
                    </div>
                  </ng-container>
                </div>
              </td>
              <td *ngIf="itemType != 'support'">
                <input
                  (change)="emitEvent()"
                  mask="separator.5"
                  thousandSeparator="."
                  decimalMarker=","
                  type="text"
                  class="form-control"
                  placeholder="Insira o valor"
                  [(ngModel)]="resultList[itemType][i].dimension"
                />
              </td>
              <td *ngIf="itemType != 'support'">
                <input
                  (change)="emitEvent()"
                  mask="separator.5"
                  thousandSeparator="."
                  decimalMarker=","
                  type="text"
                  class="form-control"
                  placeholder="Insira o valor"
                  [(ngModel)]="resultList[itemType][i].dimensionX"
                />
              </td>
              <td *ngIf="itemType != 'support'">
                <input
                  (change)="emitEvent()"
                  mask="separator.5"
                  thousandSeparator="."
                  decimalMarker=","
                  type="text"
                  class="form-control"
                  placeholder="Insira o valor"
                  [(ngModel)]="resultList[itemType][i].dimensionY"
                />
              </td>

              <td colspan="2">
                <select
                  name="contractItem"
                  class="form-control custom-select"
                  (change)="handleContractItem($event.target.value, i)"
                  [(ngModel)]="resultList[itemType][i].contractItemId"
                >
                  <option value="" hidden>Selecione o item</option>
                  <optgroup label="Itens Correspondentes">
                    <option [value]="contractItem.id" *ngFor="let contractItem of item.contractItems">
                      {{ contractItemName(contractItem) }}
                    </option>
                    <option
                      *ngIf="resultList[itemType][i]?.contractItems && !resultList[itemType][i]?.contractItems?.length"
                      disabled
                    >
                      Não há itens cadastrados nesse contrato com essas características
                    </option>
                  </optgroup>

                  <optgroup label="Itens que não correspondem">
                    <option [value]="contractItem.id" *ngFor="let contractItem of item.contractItemsNotFound">
                      {{ contractItemName(contractItem) }}
                    </option>
                    <option
                    *ngIf="resultList[itemType][i]?.contractItems && !resultList[itemType][i]?.contractItemsNotFound?.length"
                    disabled
                  >
                    Não há itens cadastrados nesse contrato com essas características
                  </option>
                  </optgroup>
                </select>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="!itemList.length">
    <div class="empty-list">
      <h2>Não foi encontrado nenhum item referente ao tipo '{{ enumType[itemType] }}' no arquivo aberto.</h2>
    </div>
  </ng-container>
</ng-container>

<ng-template #signCodeModal>
  <app-sign-code-modal
    [code]="currentSign.type"
    [signType]="signTypeUpper"
    (emitCode)="handleCode($event)"
    (shareCloseModal)="closeModal()"
  >
  </app-sign-code-modal>
</ng-template>
