import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { add } from 'date-fns';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DateService } from 'src/app/services/date.service';
import { SignalElement, SignalsElement } from 'src/app/utils/signalsElements';
import {
  ComplementInformationEnum, ComplementInformationSignEnum,
  DimensionInformationEnum, IndicationVerticalEnum,
  RegulationVerticalEnum, SignalingTypeEnum,
  VerticalGroupTypeEnum, VerticalSignalingEnum, VerticalSignalizationTypeEnum, WarningVerticalEnum
} from 'src/enumerators';
import { WarrantyTypeEnum } from 'src/enumerators/action.enum';
import { Contract, ContractVerticalItemSign, ProjectTrafficSign, TrafficSignTaskActivities } from 'src/models';

defineLocale('pt-br', ptBrLocale);

@Component({

  selector: 'app-vertical-project',
  templateUrl: './vertical-project.component.html',
  styleUrls: ['../form-style.css'],
})
export class VerticalProjectComponent implements OnInit {
  @ViewChild(BsDatepickerDirective, { static: false }) datepicker?: BsDatepickerDirective; // Datepicker em uma variavel;

  activities: TrafficSignTaskActivities = new TrafficSignTaskActivities({});
  verticalSigns = {
    regulation: Object.values(RegulationVerticalEnum),
    warning: Object.values(WarningVerticalEnum),
    indication: Object.values(IndicationVerticalEnum),
  };
  verticalSignEnum = VerticalSignalingEnum;
  signalizationTypes: Array<VerticalSignalizationTypeEnum> = Object.values(VerticalSignalizationTypeEnum);
  verticalSignalizationType = VerticalSignalizationTypeEnum;
  complementInformationType = ComplementInformationEnum;
  verticalItems: ContractVerticalItemSign[] = [];
  blockDimension: { dimensionXY: boolean; dimension: boolean } = { dimensionXY: false, dimension: false };
  signalsElements: SignalElement[] = Object.values(SignalsElement);
  loading: boolean = true;
  currentItem;
  signalingTypeEnum = SignalingTypeEnum; // Enum do tipo HVD
  modalsRef?: Array<BsModalRef> = [];

  // Mock-up
  today = new Date();

  radioState = []

  @Input() trafficSignTaskActivities: any[];
  @Input() contract: Contract;
  @Input() isStock: boolean = false
  @Input() stockEntries: any[] = []
  @Output() validationEvent = new EventEmitter<boolean>();


  constructor(public dateService: DateService,
    private _modalService: BsModalService,
    private _localeService: BsLocaleService,
  ) { }

  ngOnInit(): void {
    this._localeService.use('pt-br');
    this.contract?.verticalGroups.forEach((group) => {
      if (group.groupType == VerticalGroupTypeEnum.RoadSign) {
        group.verticalItems.forEach((item: ContractVerticalItemSign) => {
          this.verticalItems.push(item);
        });
      }
    });
    this.trafficSignTaskActivities.forEach((sign) => {
      this.radioState.push(sign.stockedTrafficSignId ? { contract: false, stock: true } : { contract: true, stock: false })
      if (sign?.contractVerticalItemId && sign.warranty) {
        this.setWarranty(sign.warranty, sign);
        this.setContractItem(sign, undefined, undefined, true);
      }
      sign['isAccOpen'] = false;
    });

    this.emitValidation();
    this.loading = false;
  }

  // Remove a atividade
  removeActivity(index: number) {
    this.trafficSignTaskActivities.splice(index, 1);
    this.emitValidation();
  }

  // Defini o item do contrato para autocomplete e calculo
  setContractItem(sign: TrafficSignTaskActivities, stockedId?: string, index?: number, edit?: boolean) {
    var item: any
    if (!stockedId) {
      item = this.verticalItems.find((item) => item.id == sign?.contractVerticalItemId);
      sign.contractVerticalItem = new ContractVerticalItemSign(item);

      if (!edit) {
        if (sign.contractVerticalItemId) {
          if (sign.contractVerticalItem.warrantyType == WarrantyTypeEnum.TimeInterval) {
            sign.warranty = add(new Date(), { years: sign.contractVerticalItem.warrantyYear, months: sign.contractVerticalItem.warrantyMonth })
          }
          else if (sign.contractVerticalItem.warranty) sign.warranty = new Date(sign.contractVerticalItem.warranty);
        }
      }
      if (sign.dimension) this.calculateDimension(sign);
    } else {
      item = this.stockEntries.find((item) => item.id == stockedId).contractVerticalItem;
      let stockedItem = this.stockEntries.find(v => v.id == stockedId);
      this.trafficSignTaskActivities[index] = new TrafficSignTaskActivities(stockedItem);
      this.trafficSignTaskActivities[index].quantity = 1;
      this.trafficSignTaskActivities[index].stockedTrafficSignId = stockedId;
      this.trafficSignTaskActivities[index].contractVerticalItem = new ContractVerticalItemSign(this.verticalItems.find((item) => item.id == stockedItem.contractVerticalItemId));
      this.trafficSignTaskActivities[index].id = undefined;
      if (this.trafficSignTaskActivities[index].dimension) this.calculateDimension(this.trafficSignTaskActivities[index]);
    }

  }

  // Defini dimensao e bloqueio de inputs
  setDimension(type: 'xy' | 'total', sign: TrafficSignTaskActivities, value: number) {
    this.calculateDimension(sign);
    this.blockDimension.dimension = (value !== 0 && type == 'xy') ? true : false;
  }

  // Calcula a dimensao e atribui valor à sinalização
  calculateDimension(sign: TrafficSignTaskActivities) {
    sign.cost = sign.quantity * sign.dimension * (sign?.contractVerticalItem?.unitValue || 0);
  }

  // Seta a garantia da sinalização e barra de progresso
  setWarranty(event: Date, sign: TrafficSignTaskActivities) {
    sign['warrantyTextCurrent'] = null;
    sign['warrantyCurrent'] = null;
    sign['warrantyEnd'] = null;
    sign['progress'] = 0;
    sign['active'] = 1;
    sign.warranty = event;
    if (event) {
      const relativeTime: any[] = this.dateService.getRelativeTime(event, new Date());
      sign['warrantyTextCurrent'] = this.dateService.getTextDate(relativeTime);
      this.progressWarranty(event, sign);
    }
  }

  //Função par auxiliar na criação da barra de progresso de garantia
  progressWarranty(warranty, sign: TrafficSignTaskActivities) {
    let now = new Date();
    sign['warrantyEnd'] = this.dateService.getRelativeTime(warranty, now);
    sign['warrantyCurrent'] = this.dateService.getRelativeTime(warranty, now);

    if (sign['warrantyCurrent'][0] || sign['warrantyEnd'][0]) {
      sign['progress'] = sign['warrantyCurrent'][0] / sign['warrantyEnd'][0];
    } else {
      sign['progress'] = 0;
    }

    if (sign['progress'] <= 0) {
      sign['active'] = 3;
    } else {
      if (
        sign['warrantyCurrent'][1] <= 0 &&
        ((sign['warrantyCurrent'][2] <= 0 && sign['warrantyCurrent'][3] <= 31) ||
          (sign['warrantyCurrent'][2] == 1 && sign['warrantyCurrent'][3] == 0))
      ) {
        sign['active'] = 2;
      } else {
        sign['active'] = 1;
      }
    }
  }

  // Atribui valor do componente de informação complementar para a sinalização
  handleComplementInformation(event: { type: ComplementInformationEnum, value: string, enableComplementInformation: boolean }, sign: any) {
    sign.complementInformation = event.value;
    sign.complementInformationType = event.type;
    this.emitValidation();
  }

  // Retorna qual é o tipo da informação complementar sabendo qual é o tipo da placa WARNING/REGULATION
  complementType(sign): ComplementInformationSignEnum {
    if (sign.complementInformationType == ComplementInformationEnum.Additional) {
      if (sign.verticalType == VerticalSignalizationTypeEnum.Warning) {
        return ComplementInformationSignEnum.AdditionalWarning;
      } else {
        return ComplementInformationSignEnum.AdditionalRegulation;
      }
    } else {
      if (sign.verticalType == VerticalSignalizationTypeEnum.Warning) {
        return ComplementInformationSignEnum.IncorporatedWarning;
      } else {
        return ComplementInformationSignEnum.IncorporatedRegulation;
      }
    }
  }


  // Emite validação do form
  emitValidation() {
    let canPress = true;
    this.trafficSignTaskActivities.forEach((item: ProjectTrafficSign) => {
      if (!item.contractVerticalItemId || !item.warranty || !item.verticalCode
        || (item.quantity == 0) || (item.dimension == 0)
        || !((item['retroSwitch'] && (item.maximumRetroreflectionValue || item.minimumRetroreflectionValue)) || !item['retroSwitch'])
        || !((item.complementInformationType && item.complementInformation) || !item.complementInformationType)
      ) {
        canPress = false;
        return false;
      }
    });
    this.validationEvent.emit(canPress);
  }

  // Atribui SVG à sinalização
  setSvg(task: TrafficSignTaskActivities) {
    task['svg'] = this.signalsElements.find((sign) => sign.name.toUpperCase() == task.verticalCode.toUpperCase());
  }

  closeModal() {
    const last: any = this.modalsRef.length - 1;
    this.modalsRef[last].hide();
    this.modalsRef.pop();
  }

  // Abre MODAL
  openModalXL(template: TemplateRef<any>) {
    const modalRefAux: BsModalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
    this.modalsRef.push(modalRefAux);
  }

  setVerticalClass(): void {
    if (this.verticalSigns.indication.includes(this.currentItem.verticalCode)) {
      this.currentItem.verticalType = VerticalSignalizationTypeEnum.Indication;
    } else if (this.verticalSigns.warning.includes(this.currentItem.verticalCode)) {
      this.currentItem.verticalType = VerticalSignalizationTypeEnum.Warning;
    } else if (this.verticalSigns.regulation.includes(this.currentItem.verticalCode)) {
      this.currentItem.verticalType = VerticalSignalizationTypeEnum.Regulation;
    }
  }

  // Método chamado após selecionar a sinalização dentro do modal
  selectSignModal(event) {
    this.currentItem.verticalCode = event[0];
    this.currentItem.signCode = event[0];
    this.currentItem.activitiesList.forEach(element => {
      element.signCode = event[0];
    });
    this.setVerticalClass();
    this.emitValidation();
  }


  // Atribui valor do componente de dimensao para a sinalização
  handleDimensionInformation(event: {
    dimensionType: DimensionInformationEnum, dimension: number,
    dimensionX: number, dimensionY: number, units: number,
    diameter: number, sideLength: number, quantity: number
  }, sign) {
    sign.diameter = event.diameter;
    sign.dimension = event.dimension;
    sign.dimensionX = event.dimensionX;
    sign.dimensionY = event.dimensionY;
    sign.sideLength = event.sideLength;
    sign.dimensionType = event.dimensionType;
    this.calculateDimension(sign);
    this.emitValidation();
  }

  setBond(type, index) {
    if (type == 'stock') {
      this.radioState[index] = { stock: true, contract: false };
    } else {
      this.radioState[index] = { stock: false, contract: true };
    }
  }

  openAccordionGroup(item) {
    item.isAccOpen = !item.isAccOpen;
  }

  receiveActivitie(currentActivitieSign) {
    this.currentItem = currentActivitieSign;
  }

  remakeActivitieList(sign) {
    let activitiesList = [];
    for (let index = 0; index < sign.quantity; index++) {
      activitiesList?.push({ signCode: sign.verticalCode, latitude: undefined, longitude: undefined, latlngForm: '' })
    }

    sign.activitiesList = activitiesList;
  }
}
