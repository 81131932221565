import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardService, AuthService } from 'src/app/services';
import {
  LicenseTypeEnum, MemberRoleEnum,
  UserDutyEnum
} from 'src/enumerators';
import { AuthorizationService } from './../../services/authorization.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css'],
})
export class HeaderMenuComponent implements OnInit {
  router;
  userDutyEnum = UserDutyEnum;
  licenseTypeEnum = LicenseTypeEnum;
  memberRoleEnum = MemberRoleEnum;
  calcWidth = '';

  constructor(
    private _router: Router,
    public auth: AuthService,
    public authorizationService: AuthorizationService,
    private _authGuard: AuthGuardService,
  ) { }

  ngOnInit(): void {
    this.auth.memberSub.asObservable().subscribe(next => {
      if (!next) return
      if (this.auth?.currentMember?.role == MemberRoleEnum.TeamLeader) this.router = '/paths'
      else this.router = '/projects'

      if (this._router.url.includes('/projects') || this._router.url.includes('/region-area') || this._router.url.includes('/paths') || this._router.url.includes('/stock')) {
        this.router = this._router.url;
      }
    })

  }

  checkMemberRole(memberRoleList) {
    const check = this.authorizationService.checkMemberRole(memberRoleList);
    const element = document.getElementById("header-menu-container")
    this.calcWidth = `calc(50% - ${element.clientWidth / 2}px)`
    return check;

  }

}
