import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ItemUnitEnum, SignalingTypeEnum, TaskActivityStatusEnum, TaskStatusEnum, TaskTypeEnum } from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { DeviceSignaling, HorizontalSignaling, Task, TaskHistory, TrafficSign, VerticalSignaling } from 'src/models';
import { AuthService } from './auth.service';
import { CatalogService } from './catalog.service';
import { RestApiService } from './rest-api.service';
import { StreetViewPanorama, StreetViewTaskMarker, StreetViewTaskMarkerList, TasksActivitiesList } from 'src/models/task';
import { ContractService } from './contract.service';
import { PdfMakeService } from './pdf-make.service';

@Injectable({
  providedIn: 'root',
})
export class TaskService extends RestApiService {
  private _urlTask = '/tasks';
  private _urlTaskId = '/tasks/:id';
  private _urlTaskAmountId = '/tasks/:id?amount=true';
  private _regionByLatLng = '/regions?lat=latitude&lng=longitude';
  private _areaByLatLng = '/areas?lat=latitude&lng=longitude';
  private _getTasksData = '/service-orders/tasks-data';
  private _getTasksFromOrderId = '/tasks?serviceOrderId=:id&amount=true';
  private _taskRemoveCollection = '/tasks/remove-collection';
  private _taskFiles = '/tasks/:id/files';
  private _urltaskFilesId = '/tasks/files/:id';
  private _urlTasksProgress = '/tasks/progress';
  private _urlTaskHistory = '/tasks/:id/history';
  private _urlPatchStatus = '/tasks/update-status';
  private _urlTrafficSigns = '/traffic-signs';
  private _urlHorizontalSigns = '/horizontal-signalings';
  private _urlDeviceSigns = '/device-signalings';
  private _urlMemoryReport = '/tasks/calculation-memory-report';
  private _urlStreetViewMarkersTask = '/street-view-markers/tasks/:id';
  private _urlStreetViewMarkersPanorama = '/street-view-panoramas/tasks/:id';
  private _urlCompleteTasksAdmin = '/admin/tasks/complete';
  private _urlGetTasksActivities = '/tasks/activities';

  private _urlTasksToday = '/tasks?ofToday=true'
  private environmentEndpoint = environment.endpoint();

  @Output() pdfReadyEmit = new EventEmitter<string>();
  @Output() pdfReadyTextEmit = new EventEmitter<string>();

  constructor(
    public http: HttpClient,
    private _auth: AuthService,
    private _catalogService: CatalogService,
    private _contractService: ContractService,
    private _pdfMakeService: PdfMakeService
  ) {
    super(http);
  }

  // POST Cria Tarefa
  createTask(task: Task) {
    return this.post(this._urlTask, task, Task);
  }

  // GET Retorna lista de Tarefas
  getTasks(
    filter?: { contractId?: string, status?: Array<TaskStatusEnum>, executionDate?: Date, endDate?: Date, routeId?: string, teamsId?: Array<string>, type?: Array<TaskTypeEnum> },
    pagination?: { offset?: number; limit?: number }
  ) {
    let url = this._urlTask + '?';

    if (filter) {
      if (filter.contractId) url += `contractId=${filter.contractId}&`;
      if (filter.status?.length) {
        let listJson = JSON.stringify(filter.status);
        url += `status=${listJson}&`;
      }
      if (filter.type?.length) {
        let listJson = JSON.stringify(filter.type);
        url += `types=${listJson}&`;
      }
      if (filter.executionDate) url += `executionDate=${filter.executionDate.toISOString()}&`;
      if (filter.endDate) url += `endDate=${filter.endDate.toISOString()}&`;
      if (filter.routeId) url += `routeId=${filter.routeId}&`;
      if (filter.teamsId?.length) {
        let listJson = JSON.stringify(filter.teamsId);
        url += `teamIds=${listJson}&`;
      }
    };

    if (pagination) {
      if (pagination.offset !== undefined && pagination.limit !== undefined) {
        url += `offset=${pagination.offset}&limit=${pagination.limit}`;
      };
    };

    return this.get(url, Task);
  }

  // GET Retorna uma lista com ids das task juntos com seus progressos de atividades
  getTaskProgress() {
    const url = this.environmentEndpoint + this._urlTasksProgress;
    return this.http.get(url);
  }

  getTasksDataFromOrder() {
    return this.http.get(this.environmentEndpoint + this._getTasksData);
  }

  // GET Retorna tarefa única por ID
  getTaskById(taskId: string) {
    return this.get(this._urlTaskAmountId.replace(':id', taskId), Task);
  }

  // GET Retorna lista de tarefas pelo ID da O.S
  getTasksByOrderId(orderId: string) {
    return this.get(this._getTasksFromOrderId.replace(':id', orderId), Task);
  }

  // PUT Update na task
  updateTask(task: Task) {
    return this.put(this._urlTaskId.replace(':id', task.id), task, Task);
  }

  // Atualiza o status da tarefa
  updateTaskStatus(taskStatusBody: { id: string; status: TaskStatusEnum }) {
    const url = this.environmentEndpoint + this._urlPatchStatus;
    return this.http.patch(url, taskStatusBody);
  }

  // Deleta a task pelo id
  deleteTask(taskId: string) {
    return this.del(this._urlTaskId.replace(':id', taskId), Task);
  }

  // DELETE Remove a collection de ordem de servico
  removeTasksCollection(taskIds: string[]) {
    const url = this.environmentEndpoint + this._taskRemoveCollection;
    let params = new HttpParams().set('ids', JSON.stringify(taskIds));
    return this.http.delete(url, { params: params });
  }

  // Retorna Regioes pela latLng
  getRegionByLatLng(latLng: any[]) {
    const regionUrl = this._regionByLatLng.replace('latitude', latLng[0]).replace('longitude', latLng[1]);
    const url = this.environmentEndpoint + regionUrl;
    return this.http.get(url);
  }

  // Retorna Areas pela latLng
  getAreaByLatLng(latLng: any[]) {
    const areaUrl = this._areaByLatLng.replace('latitude', latLng[0]).replace('longitude', latLng[1]);
    const url = this.environmentEndpoint + areaUrl;
    return this.http.get(url);
  }

  // Salva arquivos de tarefas
  uploadTaskFiles(files: [{ file: File; url: SafeResourceUrl }] | any, taskId: string) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file.file);
    }

    const url = this.environmentEndpoint + this._taskFiles.replace(':id', taskId);
    return this.http.post(url, formData, { headers: reqHeaders });
  }
  // Baixa o arquivo da tarefa
  downloadTaskFile(fileId: string) {
    const url = this.environmentEndpoint + this._urltaskFilesId.replace(':id', fileId);
    return this.http.get<{ url: string }>(url);
  }

  // Deleta o arquivo da tarefa
  deleteTaskFile(fileId: string) {
    const url = this.environmentEndpoint + this._urltaskFilesId.replace(':id', fileId);
    return this.http.delete(url);
  }

  // Calculo de total de task
  getTaskCost(task: Task, editMode: boolean, createMode: boolean) {
    let trafficSignAmount = 0;
    let supportAmount = 0;
    let horizontalAmount = 0;
    let deviceAmount = 0;
    let contractServiceItemAmount = 0;

    if (task.trafficSignTaskActivities.length)
      trafficSignAmount = this.calculateActivities(task.trafficSignTaskActivities);

    if (task.supportTaskActivities.length) supportAmount = this.calculateActivities(task.supportTaskActivities);

    if (task.horizontalSignalingTaskActivities.length)
      horizontalAmount = this.calculateActivities(task.horizontalSignalingTaskActivities);

    if (task.deviceSignalingTaskActivities.length)
      deviceAmount = this.calculateActivities(task.deviceSignalingTaskActivities);

    if (task.removalTrafficSignTaskActivities.length)
      trafficSignAmount = this.calculateActivities(task.removalTrafficSignTaskActivities);

    if (task.removalHorizontalSignalingTaskActivities.length)
      trafficSignAmount = this.calculateActivities(task.removalHorizontalSignalingTaskActivities);

    if (task.removalDeviceSignalingTaskActivities.length)
      trafficSignAmount = this.calculateActivities(task.removalDeviceSignalingTaskActivities);

    if (task.maintenanceTrafficSignTaskActivities.length)
      trafficSignAmount = this.calculateActivities(task.maintenanceTrafficSignTaskActivities);

    if (task.maintenanceHorizontalSignalingTaskActivities.length)
      trafficSignAmount = this.calculateActivities(task.maintenanceHorizontalSignalingTaskActivities);

    if (task.maintenanceDeviceSignalingTaskActivities.length)
      trafficSignAmount = this.calculateActivities(task.maintenanceDeviceSignalingTaskActivities);

    if (task.contractServiceItems.length) {
      contractServiceItemAmount = task.contractServiceItems.reduce((sum, currentItem) => {
        return sum + currentItem.contractServiceItem.unitValue * currentItem.quantity;
      }, 0);
    }

    // Verifica se é substituição e chama outro método para calcular
    if (
      task.replacementVerticalSignalingTaskActivities.length ||
      task.replacementHorizontalSignalingTaskActivities.length
    ) {
      task.replacementVerticalSignalingTaskActivities.length
        ? this.calculateReplacementTaskCost(task, task.replacementVerticalSignalingTaskActivities, 'vertical', editMode, createMode)
        : this.calculateReplacementTaskCost(task, task.replacementHorizontalSignalingTaskActivities, 'horizontal', editMode, createMode);
    } else {
      task.amount = trafficSignAmount + supportAmount + horizontalAmount + deviceAmount + contractServiceItemAmount;
    }
  }

  // Retorna a soma do custo de cada array de atividade
  private calculateActivities(activity: any[]): number {
    let sum: number = 0;
    sum = activity.reduce((sum, el) => {
      return sum + el.cost;
    }, 0);
    return sum;
  }

  /* Achei interessante dividir o calculo
  de tarefas de substituição em outro
  método pela lógica ser diferente*/
  public calculateReplacementTaskCost(task: Task, activities: any[], type: 'vertical' | 'horizontal', editMode: boolean, createMode: boolean) {
    let activityAmount = 0;
    let supportActivityAmount = 0;
    let contractServiceItemAmount = 0;

    // valor da atividade
    if (type == 'vertical') {
      activityAmount = task.replacementVerticalSignalingTaskActivities.reduce((sum, currentActivity) => {

        if (!currentActivity.replacementTrafficSignTaskActivity.keep) {
          if (editMode || createMode) {
            currentActivity.replacementTrafficSignTaskActivity.serviceItems.forEach(item => {
              if (item) {
                if (item.unit == ItemUnitEnum.m || item.unit == ItemUnitEnum.m2)
                  sum += item.unitValue * currentActivity.replacementTrafficSignTaskActivity.dimension;
                else sum += item.unitValue

              }
            });
          }

          if (!editMode && !createMode) sum += currentActivity.replacementTrafficSignTaskActivity.cost
          else sum += currentActivity.replacementTrafficSignTaskActivity.contractItem.unitValue * currentActivity.replacementTrafficSignTaskActivity.dimension;

          return sum
        }
        else
          return sum;
      }, 0);

      supportActivityAmount = task.replacementVerticalSignalingTaskActivities.reduce((sum, currentActivity) => {
        if (!currentActivity.replacementSupportTaskActivity.keep) {
          if (editMode || createMode) {
            currentActivity.replacementSupportTaskActivity.serviceItems.forEach(item => {
              if (item) sum += (item.unitValue || 0)

            });
          }
          if (!editMode && !createMode) sum += (currentActivity.replacementSupportTaskActivity.cost || 0);
          else sum += (currentActivity.replacementSupportTaskActivity.contractItem.unitValue || 0);

          return sum
        }
        else
          return sum;
      }, 0);

    } else {
      activityAmount = activities.reduce((sum, currentActivity) => {

        if (editMode || createMode) {
          currentActivity.serviceItems.forEach(item => {
            if (item) {
              if (item.unit == ItemUnitEnum.m || item.unit == ItemUnitEnum.m2) {
                sum += item.unitValue * currentActivity.dimension;
              } else {
                sum += item.unitValue
              }
            }
          });
        }
        if (!editMode && !createMode) sum += currentActivity.cost;
        else sum += currentActivity.contractHorizontalItem.unitValue * currentActivity.dimension;
        return sum
      }, 0);
    }
    task.amount = activityAmount + supportActivityAmount;
  }

  // GET Histórico da tarefa
  getTaskHistory(taskId: string) {
    const url = this._urlTaskHistory.replace(':id', taskId);
    return this.get(url, TaskHistory);
  }

  // GET Retorna lista de traffics associadas à task
  getTrafficAssociatedSigns(taskId: string, files?: boolean) {
    let url = this._urlTrafficSigns + `?taskId=${taskId}`;
    if (files) url += '&files=true'
    return this.get(url, TrafficSign);
  }

  // GET Retorna lista de horizontais associadas à task
  getHorizontalAssociatedSigns(taskId: string, files?: boolean) {
    let url = this._urlHorizontalSigns + `?taskId=${taskId}`;
    if (files) url += '&files=true'
    return this.get(url, HorizontalSignaling);
  }

  // GET Retorna lista de dispositivos associadas à task
  getDeviceAssociatedSigns(taskId: string, files?: boolean) {
    let url = this._urlDeviceSigns + `?taskId=${taskId}`;
    if (files) url += '&files=true'
    return this.get(url, DeviceSignaling);
  }

  // Retorna as tarefas de uma ordem de serviço com data de execução e termino
  getTasksWithRange(executionDate: Date, endDate?: Date, routeId?: string) {
    let url = this.environmentEndpoint + this._urlTask + '?';
    const execDate = executionDate ? `executionDate=${new Date(executionDate).toISOString()}&` : '';
    const eDate = endDate ? `endDate=${new Date(endDate).toISOString()}&` : '';
    const route = routeId ? `routeId=${routeId}&` : '';
    url = url + execDate + eDate + route;
    return this.http.get(url)
  }

  // Emite um relatório de memória de cálculo
  emitCalculationMemoryReport(taskIds: string[], financial: boolean) {
    const url = this.environmentEndpoint + this._urlMemoryReport;
    const params = new HttpParams().appendAll({
      taskIds: JSON.stringify(taskIds),
      financial: financial,
    });
    return this.http.get(url, { params: params });
  }

  async buildTaskForReport(task: Task, imageView?: boolean) {
    task['reportSigns'] = [];
    switch (task.signalingType) {
      // Verticais
      case SignalingTypeEnum.Vertical:
        const signRes: TrafficSign[] = (await this.getTrafficAssociatedSigns(task.id, true).toPromise() as TrafficSign[]);
        signRes.forEach(sign => {
          sign['verticalSignalingFiles'] = sign['verticalSignaling']['verticalSignalingFiles'];
          sign['type'] = SignalingTypeEnum.Vertical;
          task['reportSigns'].push(sign);
        });
        break;

      // Horizontais
      case SignalingTypeEnum.Horizontal:
        const horizontalSignRes: HorizontalSignaling[] = (await this.getHorizontalAssociatedSigns(task.id, true).toPromise() as HorizontalSignaling[]);
        horizontalSignRes.forEach(sign => {
          task['reportSigns'].push(sign);
        });
        break;

      // Dispositivos
      case SignalingTypeEnum.Device:
        const deviceSignRes: DeviceSignaling[] = (await this.getDeviceAssociatedSigns(task.id, true).toPromise() as DeviceSignaling[]);
        deviceSignRes.forEach(sign => {
          task['reportSigns'].push(sign);
        });
        break;
    }

    return task;
  }

  getTodayTask(taskTypeList: Array<TaskTypeEnum>) {
    const url = this._urlTasksToday;
    return this.get(url, Task);
  }

  getPanoramaStreetViewTask(taskId) {
    const url = this._urlStreetViewMarkersPanorama.replace(':id', taskId);
    return this.get(url, StreetViewPanorama);
  }

  postPanoramaStreetViewTask(taskId, panorama: StreetViewPanorama) {
    const url = this._urlStreetViewMarkersPanorama.replace(':id', taskId);
    return this.post(url, panorama, StreetViewPanorama);
  }

  putPanoramaStreetViewTask(taskId: string, panorama: StreetViewPanorama) {
    const url = this._urlStreetViewMarkersPanorama.replace(':id', taskId);
    return this.put(url, panorama, StreetViewPanorama);
  }

  getMarkersStreetViewTask(taskId) {
    const url = this._urlStreetViewMarkersTask.replace(':id', taskId);
    return this.get(url, StreetViewTaskMarker);
  }

  postMarkersStreetViewTask(taskId, markerList: StreetViewTaskMarkerList) {
    const url = this._urlStreetViewMarkersTask.replace(':id', taskId);
    return this.post(url, markerList, StreetViewTaskMarkerList);
  }

  putMarkersStreetViewTask(taskId, markerList: StreetViewTaskMarkerList) {
    const url = this._urlStreetViewMarkersTask.replace(':id', taskId);
    return this.put(url, markerList, StreetViewTaskMarkerList);
  }

  patchCompleteTasks(taskList) {
    const url = this.environmentEndpoint + this._urlCompleteTasksAdmin;
    return this.http.patch(url, { tasksIds: taskList });
  }


  // verica os reajustes dos itens do contrato
  checkForAdjustments(task: Task) {
    task.trafficSignTaskActivities.forEach((trafficSign) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        trafficSign.contractVerticalItem.adjustments,
      );
      if (contractAdjustmentItem) trafficSign.contractVerticalItem.unitValue = contractAdjustmentItem.unitValue;
    });

    task.supportTaskActivities.forEach((taskSupport) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        taskSupport.contractVerticalItem.adjustments,
      );
      if (contractAdjustmentItem) taskSupport.contractVerticalItem.unitValue = contractAdjustmentItem.unitValue;
    });

    task.horizontalSignalingTaskActivities.forEach((horizontalSignaling) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        horizontalSignaling.contractHorizontalItem.adjustments,
      );
      if (contractAdjustmentItem)
        horizontalSignaling.contractHorizontalItem.unitValue = contractAdjustmentItem.unitValue;
    });

    task.deviceSignalingTaskActivities.forEach((deviceSignaling) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        deviceSignaling.contractDeviceItem.adjustments,
      );
      if (contractAdjustmentItem) deviceSignaling.contractDeviceItem.unitValue = contractAdjustmentItem.unitValue;
    });

    task.replacementVerticalSignalingTaskActivities.forEach((verticalReplacementGroup) => {
      if (verticalReplacementGroup.replacementTrafficSignTaskActivity.contractItem) {
        const contractAdjustmentTrafficSignItem = this._contractService.getItemWithClosestDate(
          verticalReplacementGroup.replacementTrafficSignTaskActivity.contractItem.adjustments,
        );
        if (contractAdjustmentTrafficSignItem)
          verticalReplacementGroup.replacementTrafficSignTaskActivity.contractItem.unitValue =
            contractAdjustmentTrafficSignItem.unitValue;
      }
      if (verticalReplacementGroup.replacementSupportTaskActivity.contractItem) {
        const contractAdjustmentSupportItem = this._contractService.getItemWithClosestDate(
          verticalReplacementGroup.replacementSupportTaskActivity.contractItem.adjustments,
        );
        if (contractAdjustmentSupportItem)
          verticalReplacementGroup.replacementSupportTaskActivity.contractItem.unitValue =
            contractAdjustmentSupportItem.unitValue;
      }
    });

    task.replacementHorizontalSignalingTaskActivities.forEach((horizontalReplacementItem) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        horizontalReplacementItem.contractHorizontalItem.adjustments,
      );
      if (contractAdjustmentItem)
        horizontalReplacementItem.contractHorizontalItem.unitValue = contractAdjustmentItem.unitValue;
    });
  }

  createFilterUrl(url, optionsFilter) {
    let listJson;

    if (optionsFilter?.length) {
      optionsFilter.forEach((filter: any) => {

        if (filter.selected.length > 0) {
          switch (filter.value) {
            case 'Contrato':
              url += `contractId=${filter.selected}&`;
              break;

            case 'Data de Execução':
              let start = filter.selected[0][0] ? new Date(new Date(filter.selected[0][0]).setHours(-3, 0, 0)) : undefined;
              let end = filter.selected[0][1] ? new Date(new Date(filter.selected[0][1]).setHours(20, 59, 59)) : undefined;
              const startM = `executionDate=${start.toISOString()}&`;
              const endM = `endDate=${end.toISOString()}&`;
              url += startM;
              url += endM;
              break;

            case 'Status':
              listJson = JSON.stringify(filter.selected);
              url += `status=${listJson}&`;
              break;

            case 'Tipos':
              listJson = JSON.stringify(filter.selected);
              url += `types =${listJson}&`;
              break;

            case 'Líder de Equipe':
              listJson = JSON.stringify(filter.selected);
              url += `teamIds=${listJson}&`;
              break;

            default:
              break;
          }
        }
      })
    }
    return url;
  }

  // Obter Sinalizações dos Projetos
  getTasksActivities(
    querys?: { limit?: number; offset?: number, serviceOrderIds?: Array<string>, status?: Array<TaskActivityStatusEnum>, location?: boolean },
  ) {
    const params = new URLSearchParams();

    if (querys?.limit !== undefined) {
      params.append('limit', querys.limit.toString());
    }

    if (querys?.offset !== undefined) {
      params.append('offset', querys.offset.toString());
    }

    if (querys?.serviceOrderIds.length) {
      params.append('serviceOrderIds', JSON.stringify(querys?.serviceOrderIds));
    }

    if (querys?.location) params.append('location', 'true')

    const queryString = params.toString();
    const url = `${this._urlGetTasksActivities}${queryString ? `?${queryString}` : ''}`;

    return this.get(url, TasksActivitiesList);
  }

}
