import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { LicenseTypeEnum, UserDutyEnum } from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { Organization } from '../models';
import { SocketService } from './socket.service';
import { MemberRemovalRequestAttributes } from 'src/models';
import { RestApiService } from './rest-api.service';
import { AppVersion, GlobalNotification } from 'src/models/master';
@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends RestApiService {
  public organization: Organization // Organização persistida como variavel publica

  environmentEndpoint = environment.endpoint();
  uri = environment.uri();
  _urlOrganizations = '/organizations';
  _urlOrganizationUsers = '/organization';
  _urlOrganization = '/organizations/:orgId';
  _urlOwnerInvitation = '/owner-invitations';
  _urlOrganizationInvites = '/invites';
  _urlRemoveUser = '/user/:id';
  _urlRemoveMember = '/member/:id';
  _urlChangeUserDuty = '/user/change-duty';
  _urlGetWorkspace = '/workspace';
  _urlGetWorkspaces = '/workspaces';
  _urlSwitchWorkspace = '/switch-workspace';
  _urlGetOrganizationsUser = '/user/organizations';
  _urlChangeLicense = '/master/organizations/:id/license';
  _urlMembers = '/workspace-members';
  _urlGroups = '/groups';
  _urlGroupsId = '/groups/:id';
  _urlGroupsMembers = '/groups/:id/members';
  _urlMemberRemovalRequest = '/member-removal-requests/';
  _urlChangeOwner = '/user/change-owner';
  _urlAppVerion = '/app-version';
  _urlMasterNotification = '/notifications';
  _urlOrganizationNotification = '/notifications/organization';

  currentWorkspace;
  workSub: BehaviorSubject<any> = new BehaviorSubject(undefined);

  _urlUploadImage = '/organizations/:id/image'

  _httpOptions = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this._auth.getJwtToken()}`,
  });

  listWorkspaces = [];
  newOrganizationId;
  switchOrg: boolean = false;
  modalEmail: string;
  switchOrgSub: BehaviorSubject<Array<any>> = new BehaviorSubject(undefined);

  constructor(
    private _http: HttpClient,
    private _route: Router,
    private _auth: AuthService,
    private _socket: SocketService,
  ) {
    super(_http);
  }

  // Cria organização
  createOrg(orgForm) {
    const body = { name: orgForm?.value.name, cnpj: orgForm?.value.cnpj, licenseType: orgForm?.value.licenseType };
    const url = this.environmentEndpoint + this._urlOrganizations;
    return this._http
      .post<any>(url, body, { headers: this._httpOptions })
      .pipe(tap((res) => this.inviteOwner(res.id, orgForm.value)));
  }

  // Convida 'Owner' por e-mail na criação da org
  inviteOwner(orgId, orgForm) {
    const body = {
      firstName: orgForm.firstName,
      lastName: orgForm.lastName,
      email: orgForm.email,
      originUrl: this.uri,
    };
    const url = this.environmentEndpoint + this._urlOrganization.replace(':orgId', orgId) + this._urlOwnerInvitation;
    return this._http.post<any>(url, body, { headers: this._httpOptions }).subscribe(
      (res) => console.log(res.message),
      (error) => {
        console.log('invite error: ', error);
      },
      () => console.log('email enviado'),
    );
  }

  setWorkspace() {
    this.getWorkspace().subscribe((res: any) => {
      this.currentWorkspace = res;
      this.currentWorkspace = this.listWorkspaces.find(workspace => workspace.id == this.currentWorkspace.id);
      this.workSub.next(this.currentWorkspace);
    });
  }

  // Método de Reenvio de e-mail
  sendEmailAgain(orgId) {
    this.getOwner(orgId).subscribe((res) => {
      this.inviteOwner(orgId, res[0]);
    });
  }

  // Recebe dados do usuário do último convite
  getOwner(orgId) {
    const url = this.environmentEndpoint + this._urlOrganization.replace(':orgId', orgId) + this._urlOwnerInvitation;
    return this._http.get<any>(url, { headers: this._httpOptions });
  }

  // Recebe lista com organizações e salva em this.orgs
  getOrgs() {
    const url = this.environmentEndpoint + this._urlOrganizations;
    return this._http.get(url, { headers: this._httpOptions });
  }

  // pegar Email e abrir modal
  getEmail(orgId) {
    const url = this.environmentEndpoint + this._urlOrganization.replace(':orgId', orgId) + this._urlOwnerInvitation;
    return this._http.get<any>(url, { headers: this._httpOptions });
  }

  // Retorna a organização do usuário atual
  getCurrentOrg() {
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
    });
    const url = this.environmentEndpoint + this._urlOrganizationUsers;

    return this._http.get(url, { headers: reqHeaders }).pipe(
      tap((org: Organization) => {
        this.organization = org
      })
    );
  }

  editOrganization(orgId, orgForm) {
    const url = this.environmentEndpoint + this._urlOrganization.replace(':orgId', orgId);
    return this._http.put(url, orgForm, { headers: this._httpOptions });
  }

  inviteOrgMember(form) {
    const url = this.environmentEndpoint + this._urlOrganizationUsers + this._urlOrganizationInvites;
    return this._http.post(
      url,
      {
        firstName: form.firstName,
        lastName: form.lastName,
        duty: form.duty,
        email: form.email,
        originUrl: this.uri,
      },
      {
        headers: this._httpOptions,
      },
    );
  }

  removeInviteMember(emailEmployee: string) {
    const options = {
      headers: this._httpOptions,
      body: { email: emailEmployee },
    };
    const url = this.environmentEndpoint + this._urlOrganizationUsers + this._urlOrganizationInvites;
    return this._http.delete(url, options);
  }

  removeOrgMember(id: string) {
    const url = this.environmentEndpoint + this._urlRemoveMember.replace(':id', id);
    return this._http.delete(url);
  }

  changeMemberDuty(id: string, duty: string) {
    const url = this.environmentEndpoint + this._urlChangeUserDuty;
    return this._http.put(url, {
      userId: id,
      newDuty: duty
    });
  }

  switchWorkspace(id) {
    const url = this.environmentEndpoint + this._urlSwitchWorkspace + `?workspaceId=${id}`;
    return this._http.post(url, {});
  }

  setSwitchWorkspace(workspace, bool) {
    this._auth.doLoginUser(workspace, bool);
    this.switchOrg = true;
    this.setWorkspace();
    this._socket.StopNotificationsSocket();
    this._socket.StartNotificationsSocket();
  }

  getWorkspace() {
    const url = this.environmentEndpoint + this._urlGetWorkspace;
    return this._http.get(url);
  }

  getWorkspaces() {
    const url = this.environmentEndpoint + this._urlGetWorkspaces;
    return this._http.get(url);
  }

  getOrganizationById(orgId) {
    const url = this.environmentEndpoint + this._urlOrganization.replace(':orgId', orgId);
    return this._http.get(url);
  }
  editOrganizationLicense(orgId: string, license: LicenseTypeEnum) {
    const url = this.environmentEndpoint + this._urlChangeLicense.replace(':id', orgId)
    return this._http.put(url, { licenseType: license })
  }

  uploadImages(file: File, orgId: string) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url = this.environmentEndpoint + this._urlUploadImage.replace(':id', orgId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  // Lista membros da WORKSPACE
  listAllWorkspaceUsers(userDuty?: UserDutyEnum, registeredSignaling?: boolean) {
    let url = this.environmentEndpoint + this._urlMembers;
    if (userDuty) url += `?userDuty=${userDuty}`;
    if (registeredSignaling) url += `?registeredSignaling=${registeredSignaling}`;

    return this._http.get(url);
  }

  getGroups() {
    const url = this.environmentEndpoint + this._urlGroups;
    return this._http.get(url);
  }

  getGroupId(groupId) {
    const url = this.environmentEndpoint + this._urlGroupsId.replace(':id', groupId);
    return this._http.get(url);
  }

  postGroup(group) {
    const url = this.environmentEndpoint + this._urlGroups;
    return this._http.post(url, group);
  }

  putGroup(group, groupId) {
    const url = this.environmentEndpoint + this._urlGroupsId.replace(':id', groupId);
    return this._http.put(url, group);
  }

  deleteGroup(groupId) {
    const url = this.environmentEndpoint + this._urlGroupsId.replace(':id', groupId);
    return this._http.delete(url);
  }

  getGroupMembers(groupId) {
    const url = this.environmentEndpoint + this._urlGroupsMembers.replace(':id', groupId);
    return this._http.get(url);
  }

  putGroupMember(memberIdList, groupId) {
    const url = this.environmentEndpoint + this._urlGroupsMembers.replace(':id', groupId);
    return this._http.put(url, { ids: memberIdList });
  }

  isMeasurementReportEnabled() {
    if (
      this.currentWorkspace.organizationId == '3e1fdee0-7f75-11ec-960d-bb505088e60d' ||
      this.environmentEndpoint.includes('hellius.dev.')
    ) {
      return true;
    }
    return false;
  }

  isMeasurementReportDetranBAEnabled() {
    if (
      this.currentWorkspace.organizationId == '1e835990-dd79-11ee-8aeb-5df8769b0208' ||
      this.environmentEndpoint.includes('hellius.dev.')
    ) {
      return true;
    }
    return false;
  }

  isMeasurementReportJoaoPessoaEnabled() {
    if (
      this.currentWorkspace.organizationId == 'b25f6580-b232-11ef-9c9f-d9fa0be7018e' ||
      this.environmentEndpoint.includes('hellius.dev.')
    ) {
      return true;
    }
    return false;
  }

  getRemovalMembers() {
    const url = this._urlMemberRemovalRequest;
    return this.get(url, MemberRemovalRequestAttributes);
  }

  postRemovalMembers(removalMember) {
    const url = this._urlMemberRemovalRequest;
    return this.post(url, removalMember, MemberRemovalRequestAttributes);
  }

  deleteRemovalMembers(idRemovalMember) {
    const url = this._urlMemberRemovalRequest + idRemovalMember;

    return this.del(url, MemberRemovalRequestAttributes);
  }

  changeOwnerOrganization(userId, password) {
    const url = this.environmentEndpoint + this._urlChangeOwner;
    return this._http.put(url, { userId: userId, password })
  }

  getAppVersion() {
    const url = this._urlAppVerion;
    return this.get(url, AppVersion);
  }

  putAppVersion(body: AppVersion) {
    const url = this._urlAppVerion;
    return this.put(url, body, AppVersion);
  }

  postMasterNotification(body: GlobalNotification) {
    const url = this._urlMasterNotification;
    return this.post(url, body, GlobalNotification);
  }

  postOrganizationNotification(body: GlobalNotification) {
    const url = this._urlOrganizationNotification;
    return this.post(url, body, GlobalNotification);
  }

}
