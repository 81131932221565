import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { add } from 'date-fns';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ContractService } from 'src/app/services/contract.service';
import { PathService } from 'src/app/services/path.service';
import { ProjectService } from 'src/app/services/project.service';
import { RegionAreaService } from 'src/app/services/region-area.service';
import { enumerators } from 'src/dictionaries/enum.pt-br';
import {
  HorizontalSignalingEnum,
  IndicationVerticalEnum,
  RegulationVerticalEnum,
  SignalingTypeEnum,
  VerticalGroupTypeEnum,
  VerticalSignalingEnum,
  VerticalSignalizationTypeEnum,
  WarningVerticalEnum,
} from 'src/enumerators';
import { WarrantyTypeEnum } from 'src/enumerators/action.enum';
import {
  Area,
  Contract,
  ContractVerticalItemSign,
  Path,
  Project,
  ProjectDeviceSignaling,
  ProjectHorizontalSignaling,
  ProjectSupport,
  ProjectTrafficSign,
  Region,
  VerticalProjectImportFile,
} from 'src/models';
import { DeviceProjectImportFile, HorizontalProjectImportFile, ProjectImportFile } from 'src/models/project';
import { read, utils } from 'xlsx';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css'],
})
export class NewProjectComponent implements OnInit {
  currentContract: Contract; // Contrato escolhido
  project: Project = new Project({ createdAt: new Date() }); // Projeto sendo Criado/Editado
  regionsList: Array<Region> = []; // Lista de região
  areasList: Array<Area> = []; // Lista de áreas
  pathsList: Array<Path> = []; // Lista de trajetos
  contractsList: Array<Contract> = []; // Lista de contratos
  uploadedFiles: Array<any> = []; // Lista de arquivos
  pageController = 'projectData'; // Controlador da página
  signTypeEnum = SignalingTypeEnum; // Enum da sinalização HVD
  verticalGroupTypeEnum = VerticalGroupTypeEnum;
  formValidation = false; // Validação dos formulários de HVD
  editMode = false; // Saber se é edição
  loading = true;
  loadingModal = true;
  loadingContract = false;
  selectedRegions: Array<Region> = []; // Regiões selecionadas do input de select
  selectedAreas: Array<Area> = []; // Áreas selecionadas do input de select
  selectedPaths: Array<Path> = []; // Trajetos selecionadas do input de select
  overflowCounter: {
    region: number;
    area: number;
    path: number;
  } = { region: 0, area: 0, path: 0 }; // Contabiliza o numero de RAP adicionais no select, contatador de cada caso
  hiddenRegionIds: Array<string> = []; // Lista de regiões escondias no select
  hiddenAreaIds: Array<string> = []; // Lista de áreas escondias no select
  hiddenPathIds: Array<string> = []; // Lista de trajetos escondias no select
  enumerators = enumerators; // Enumeradores

  // Referencia do modal
  modalsRef?: Array<BsModalRef> = [];

  projectImportType: 'vertical' | 'horizontal' | 'device' | 'support' = 'vertical'; // visualização do modal

  enumType = {
    vertical: 'Vertical',
    support: 'Suporte',
    horizontal: 'Horizontal',
    device: 'Dispositivos',
  };

  // Variáveis para import do projeto via csv
  itemsProjectImport: ProjectImportFile = new ProjectImportFile();
  resultProjectImport: ProjectImportFile;
  fileImport;
  verticalSigns = {
    regulation: Object.values(RegulationVerticalEnum),
    warning: Object.values(WarningVerticalEnum),
    indication: Object.values(IndicationVerticalEnum),
  };

  constructor(
    private _regionArea: RegionAreaService,
    private _contractService: ContractService,
    private _activedRoute: ActivatedRoute,
    private _project: ProjectService,
    private _modalService: BsModalService,
    private _pathService: PathService,
    private _route: Router,
  ) { }

  // Referencias para o import do Projeto
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('projectImport') projectImport!: TemplateRef<any>;

  // Referencia da barra de regiao e area
  @ViewChild('areaRow') areaRow: ElementRef<HTMLElement>;
  @ViewChild('regionRow') regionRow: ElementRef<HTMLElement>;
  @ViewChild('pathRow') pathRow: ElementRef<HTMLElement>;
  @ViewChild('areaContainer') areaContainer: ElementRef<HTMLElement>;
  @ViewChild('regionContainer') regionContainer: ElementRef<HTMLElement>;
  @ViewChild('pathContainer') pathContainer: ElementRef<HTMLElement>;

  ngOnInit(): void {
    this.project.projectDeviceSignalings.push(new ProjectDeviceSignaling({ warranty: new Date() }));
    this.project.projectHorizontalSignalings.push(new ProjectHorizontalSignaling({ warranty: new Date() }));
    this.project.projectTrafficSigns.push(new ProjectTrafficSign({ warranty: new Date() }));
    this._activedRoute.params.subscribe((res) => {
      if (res.id) {
        this.editMode = true;
        this._project.getProject(res.id).subscribe((next: Project) => {
          this.project = next;
          this.createProjectActivieList();
          this.checkForAdjustments(this.project);
          this.populateRegionAreaPathList();
          this.uploadedFiles = this.project.projectFiles;
          this._contractService.getContract(this.project?.contractId).subscribe((res: any) => {
            this.currentContract = res;
            this._contractService.checkForContractAdjustments(this.currentContract);
          });
          this.loading = false;
        });
      } else {
        this.project = new Project({ createdAt: new Date() });
        this.populateRegionAreaPathList();
        this.loading = false;
      }
    });

    // Populando lista de contratos
    this._contractService.listContracts().subscribe((res: any) => (this.contractsList = res));
  }

  checkForAdjustments(project: Project) {
    project.projectTrafficSigns.forEach((trafficSign) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        trafficSign.contractVerticalItem.adjustments,
      );
      if (contractAdjustmentItem) trafficSign.contractVerticalItem.unitValue = contractAdjustmentItem.unitValue;
    });

    project.projectSupports.forEach((projectSupport) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        projectSupport.contractVerticalItem.adjustments,
      );
      if (contractAdjustmentItem) projectSupport.contractVerticalItem.unitValue = contractAdjustmentItem.unitValue;
    });

    project.projectHorizontalSignalings.forEach((horizontalSignaling) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        horizontalSignaling.contractHorizontalItem.adjustments,
      );
      if (contractAdjustmentItem)
        horizontalSignaling.contractHorizontalItem.unitValue = contractAdjustmentItem.unitValue;
    });

    project.projectDeviceSignalings.forEach((deviceSignaling) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(
        deviceSignaling.contractDeviceItem.adjustments,
      );
      if (contractAdjustmentItem) deviceSignaling.contractDeviceItem.unitValue = contractAdjustmentItem.unitValue;
    });
  }

  // Verifica quebra de width no select de regiao e area
  checkCounterBreak() {
    setTimeout(() => {
      this.overflowCounter = { region: 0, area: 0, path: 0 };
      this.selectedRegions.forEach((region, index) =>
        this.checkRegionLength(this.selectedRegions[this.selectedRegions.length - (index + 1)]),
      );
      this.selectedAreas.forEach((area, index) =>
        this.checkAreaLength(this.selectedAreas[this.selectedAreas.length - (index + 1)]),
      );
    }, 200);
  }

  goAhead() {
    this.pageController = this.pageController == 'projectData' ? SignalingTypeEnum.Vertical : 'resume';
  }

  goBack() {
    if (this.pageController == 'projectData') this._route.navigate(['/projects']);
    else this.pageController = 'projectData';
  }

  handleFormValidation(event) {
    this.formValidation = event;
  }

  // Método para popular lista de Regiao e Area
  populateRegionAreaPathList() {
    // Populando lista de regiões
    this._regionArea.getRegions().subscribe((res: Array<any>) => {
      res.forEach((region) => {
        const sameRegion = this.selectedRegions.find((selectedRegion) => {
          return region.id == selectedRegion.id;
        });
        if (!sameRegion) {
          this.regionsList.push(region);
        }
      });
      if (this.editMode) {
        this.project.regions.forEach((region) => {
          this.setRegionAreaPath(region.id, 'region');
        });
      }
    });
    // Populando lista de áreas
    this._regionArea.getAreas().subscribe((res: Array<any>) => {
      res.forEach((area) => {
        const sameArea = this.selectedAreas.find((selectedArea) => {
          return area.id == selectedArea.id;
        });
        if (!sameArea) {
          this.areasList.push(area);
        }
      });
      if (this.editMode) {
        this.project.areas.forEach((area) => {
          this.setRegionAreaPath(area.id, 'area');
        });
      }
    });
    // Populando lista de Trajetos
    this._pathService.getPaths(['id', 'name']).subscribe((res: Array<any>) => {
      res.forEach((path) => {
        const samePath = this.selectedPaths.find((selectedPath) => {
          return path.id == selectedPath.id;
        });
        if (!samePath) {
          this.pathsList.push(path);
        }
      });
      if (this.editMode) {
        this.project.paths.forEach((path) => {
          this.setRegionAreaPath(path.id, 'path');
        });
      }
    });
  }

  // seta o contrato na classe de projetos
  setContract(contractId) {
    this._contractService.getContract(contractId).subscribe((res: any) => {
      this.currentContract = res;
      this._contractService.checkForContractAdjustments(this.currentContract);
    });
  }

  // Manipula as regioes e areas selecionadas
  setRegionAreaPath(id: string, type: string) {
    if (type == 'region') {
      let index = 0;
      const region = this.regionsList.find((region, indexOfRegion) => {
        index = indexOfRegion;
        return region.id == id;
      });
      this.regionsList.splice(index, 1);
      this.selectedRegions.push(region);
      setTimeout(() => {
        this.checkRegionLength(region);
      }, 200);
    } else if (type == 'area') {
      let index = 0;
      const area = this.areasList.find((area, indexOfArea) => {
        index = indexOfArea;
        return area.id == id;
      });
      this.areasList.splice(index, 1);
      this.selectedAreas.push(area);
      setTimeout(() => {
        this.checkAreaLength(area);
      }, 200);
    } else {
      let index = 0;
      const path = this.pathsList.find((path, indexOfPath) => {
        index = indexOfPath;
        return path.id == id;
      });

      this.pathsList.splice(index, 1);
      this.selectedPaths.push(path);
      setTimeout(() => {
        this.checkPathLength(path);
      }, 200);
    }
  }

  // Analisa o tamanho da div para truncar* o display das regioes/areas
  checkRegionLength(region: Region) {
    const container = this.regionContainer.nativeElement.clientWidth;
    const row = this.regionRow.nativeElement.clientWidth;

    if (row > container / 3 - 100) {
      const regionElement = document.getElementById(`region${region.id}`) as HTMLElement;
      this.hiddenRegionIds.push(region.id);
      this.overflowCounter.region += 1;
      regionElement.hidden = true;
    }
  }

  checkAreaLength(area: Area) {
    const container = this.areaContainer.nativeElement.clientWidth;
    const row = this.areaRow.nativeElement.clientWidth;

    if (row > container - 100) {
      const areaElement = document.getElementById(`area${area.id}`) as HTMLElement;
      this.hiddenAreaIds.push(area.id);
      this.overflowCounter.area += 1;
      areaElement.hidden = true;
    }
  }

  checkPathLength(path: Path) {
    const container = this.pathContainer.nativeElement.clientWidth;
    const row = this.pathRow.nativeElement.clientWidth;

    if (row > container - 100) {
      const pathElement = document.getElementById(`path${path.id}`) as HTMLElement;
      this.hiddenPathIds.push(path.id);
      this.overflowCounter.path += 1;
      pathElement.hidden = true;
    }
  }

  // Seta as Regioes e Areas na classe de Projetos
  submitRegionAreaPath() {
    this.project.regions = this.selectedRegions.map((region) => {
      return region;
    });
    this.project.regionIds = this.selectedRegions.map((region) => {
      return region.id;
    });

    this.project.areas = this.selectedAreas.map((area) => {
      return area;
    });
    this.project.areaIds = this.selectedAreas.map((area) => {
      return area.id;
    });

    this.project.paths = this.selectedPaths.map((path) => {
      return path;
    });
    this.project.pathIds = this.selectedPaths.map((path) => {
      return path.id;
    });
  }

  // Remove Região ou Área, repopula lista de regiões
  removeRegionAreaPath(type: 'region' | 'area' | 'path', item: any, index: number) {
    if (type == 'region') {
      this.regionsList.push(item);
      this.selectedRegions.splice(index, 1);
      const container = this.regionContainer.nativeElement.clientWidth;
      const row = this.regionRow.nativeElement.clientWidth;
      if (row < container - 150) {
        if (this.hiddenRegionIds.length) {
          const element = document.getElementById(`region${this.hiddenRegionIds[0]}`) as HTMLElement;
          this.hiddenRegionIds.splice(0, 1);
          element.hidden = false;
        }
        if (this.overflowCounter.region > 0) {
          this.overflowCounter.region -= 1;
        }
      }
    } else if (type == 'area') {
      this.areasList.push(item);
      this.selectedAreas.splice(index, 1);
      const container = this.areaContainer.nativeElement.clientWidth;
      const row = this.areaRow.nativeElement.clientWidth;
      if (row < container - 150) {
        if (this.hiddenAreaIds.length) {
          const element = document.getElementById(`area${this.hiddenAreaIds[0]}`) as HTMLElement;
          this.hiddenAreaIds.splice(0, 1);
          element.hidden = false;
          if (this.overflowCounter.area > 0) {
            this.overflowCounter.area -= 1;
          }
        }
      }
    } else {
      this.pathsList.push(item);
      this.selectedPaths.splice(index, 1);
      const container = this.pathContainer.nativeElement.clientWidth;
      const row = this.pathRow.nativeElement.clientWidth;
      if (row < container - 150) {
        if (this.hiddenPathIds.length) {
          const element = document.getElementById(`path${this.hiddenPathIds[0]}`) as HTMLElement;
          this.hiddenPathIds.splice(0, 1);
          element.hidden = false;
          if (this.overflowCounter.path > 0) {
            this.overflowCounter.path -= 1;
          }
        }
      }
    }
  }

  addProjectItem(type) {
    if (type == SignalingTypeEnum.Vertical) {
      this.project.projectTrafficSigns.push(new ProjectTrafficSign({ warranty: new Date() }));
    } else if (type == SignalingTypeEnum.Device) {
      this.project.projectDeviceSignalings.push(new ProjectDeviceSignaling({ warranty: new Date() }));
    } else if (type == SignalingTypeEnum.Horizontal) {
      this.project.projectHorizontalSignalings.push(new ProjectHorizontalSignaling({ warranty: new Date() }));
    } else {
      this.project.projectSupports.push(new ProjectSupport({ warranty: new Date() }));
    }
  }

  // Fecha o último modal aberto
  closeModal() {
    const last: any = this.modalsRef.length - 1;
    this.modalsRef[last].hide();
    this.modalsRef.pop();
  }

  // Abre MODAL
  openModal(template: TemplateRef<any>, size = 'modal-lg') {
    const modalRefAux: BsModalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered ' + size,
      backdrop: 'static',
    });
    this.modalsRef.push(modalRefAux);
  }

  onFileSelect(): void {
    this.fileInput.nativeElement.click();
  }

  async handleFileInput(event: Event): Promise<void> {
    try {
      this.loadingModal = true;
      this.itemsProjectImport = await this.onFileChange(event);
      this.resultProjectImport = new ProjectImportFile(JSON.parse(JSON.stringify(this.itemsProjectImport)));

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        // Processamento do arquivo, do vincen
        this.openModal(this.projectImport, 'modal-xl');
      }
      this.fileImport = null;
    } catch (error) {
      console.error('Erro ao processar o arquivo:', error);
    }
  }

  nextType() {
    if (this.projectImportType == 'vertical') this.projectImportType = 'support';
    else if (this.projectImportType == 'support') this.projectImportType = 'horizontal';
    else if (this.projectImportType == 'horizontal') this.projectImportType = 'device';
    else if (this.projectImportType == 'device') this.createProjectItems();
  }

  backType() {
    if (this.projectImportType == 'support') this.projectImportType = 'vertical';
    else if (this.projectImportType == 'horizontal') this.projectImportType = 'support';
    else if (this.projectImportType == 'device') this.projectImportType = 'horizontal';
  }

  // Preserva ordem do ngFor | keyvalue
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  handleImport(event) {
    this.resultProjectImport = event;
  }

  handleItemType(type) {
    this.projectImportType = type;
  }

  onFileChange(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = event.target.files[0];
      reader.readAsArrayBuffer(file);

      reader.onload = () => {
        try {
          const data = new Uint8Array(reader.result as ArrayBuffer);
          const workbook = read(data, { type: 'array' });

          const sheetName0 = workbook.SheetNames[0];
          const sheet0 = workbook.Sheets[sheetName0];
          const json0 = utils.sheet_to_json(sheet0);

          const sheetName1 = workbook.SheetNames[1];
          const sheet1 = workbook.Sheets[sheetName1];
          const json1 = utils.sheet_to_json(sheet1);

          const sheetName2 = workbook.SheetNames[2];
          const sheet2 = workbook.Sheets[sheetName2];
          const json2 = utils.sheet_to_json(sheet2);
          const vertical = json0
            .filter((item, index) => index > 1)
            .map((item: any) => {
              const [dimensionX, dimensionY] = item.__EMPTY_4.split('x');
              const element = {
                code: item.__EMPTY,
                type: item.__EMPTY_2,
                dimension: item.__EMPTY_3,
                dimensionX: dimensionX,
                dimensionY: dimensionY ?? 0,
                substrate: item.__EMPTY_14,
                film: item.__EMPTY_10,
              };
              const { itemsContract, itemsContractNotFound } = this.getVerticalItemsContract(element as any);
              element['contractItems'] = itemsContract;
              element['contractItemsNotFound'] = itemsContractNotFound;

              return element;
            });

          const support = json1
            .filter((item, index) => index > 1)
            .map((item: any) => ({
              code: item.__EMPTY,
              type: item.__EMPTY_12,
              material: item.__EMPTY_8,
              contractItems: [],
            }));

          const horizontal = json2
            .filter((item: any, index) => index > 1 && item.__EMPTY_18 === 'não')
            .map((item: any) => {
              const element = {
                code: item.__EMPTY,
                type: item.__EMPTY_3,
                dimensionY: item.__EMPTY_12,
                dimensionX: item.__EMPTY_13,
                dimension: item.__EMPTY_15,
                color: item.__EMPTY_16,
                material: item.__EMPTY_17,
              };

              const { itemsContract, itemsContractNotFound } = this.getHorizontalItemsContract(element as any);

              element['contractItems'] = itemsContract;
              element['contractItemsNotFound'] = itemsContractNotFound;
              return element;
            });

          const device = json2
            .filter((item: any, index) => index > 1 && item.__EMPTY_18 === 'sim')
            .map((item: any) => {
              const element = {
                code: item.__EMPTY,
                type: item.__EMPTY_3,
                dimensionY: item.__EMPTY_12,
                dimensionX: item.__EMPTY_13,
                dimension: item.__EMPTY_15,
                color: item.__EMPTY_16,
                material: item.__EMPTY_17,
              };

              const { itemsContract, itemsContractNotFound } = this.getDeviceItemsContract(element as any);
              element['contractItems'] = itemsContract;
              element['contractItemsNotFound'] = itemsContractNotFound;
              return element;
            });
          this.loadingModal = false;
          resolve({
            vertical,
            horizontal,
            support,
            device,
          });
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => {
        reject('Erro ao ler o arquivo');
      };
    });
  }

  private getVerticalItemsContract(item: VerticalProjectImportFile) {
    const itemsContract = [];
    const itemsContractNotFound = [];

    this.currentContract.verticalGroups.forEach((group, indexGroup) => {
      group.verticalItems.forEach((verticalItem: ContractVerticalItemSign, indexItem) => {
        const isFilm = verticalItem.roadSignFilmItems.find(
          (filmItem) => filmItem.roadSignFilm.name.toLocaleLowerCase() == item.film?.toLocaleLowerCase(),
        );

        verticalItem['itemNumber'] = `${indexGroup + 1}.${indexItem + 1}`;

        if (
          isFilm &&
          enumerators.RoadSignSubstrate[verticalItem.roadSignSubstrate.name]
            .toLocaleLowerCase()
            .includes(item.substrate.toLocaleLowerCase())
        ) {
          itemsContract.push(verticalItem);
        } else {
          itemsContractNotFound.push(verticalItem);
        }
      });
    });

    return { itemsContract, itemsContractNotFound };
  }

  private getHorizontalItemsContract(item: HorizontalProjectImportFile) {
    const itemsContract = [];
    const itemsContractNotFound = [];
    this.currentContract.horizontalGroups.forEach((group, indexGroup) => {
      group.horizontalItems.forEach((horizontalItem, indexItem) => {
        const similarity = this.similarityPercentage(item.material, horizontalItem.roadSignMarkingPaint.name);
        horizontalItem['itemNumber'] = `${indexGroup + 1}.${indexItem + 1}`;
        if (similarity >= 50) {
          itemsContract.push(horizontalItem);
        } else {
          itemsContractNotFound.push(horizontalItem);
        }

      });
    });

    return { itemsContract, itemsContractNotFound };
  }

  private getDeviceItemsContract(item: DeviceProjectImportFile) {
    const itemsContract = [];
    const itemsContractNotFound = [];
    this.currentContract.deviceGroups.forEach((group, indexGroup) => {
      group.deviceItems.forEach((deviceItem, indexItem) => {
        deviceItem['itemNumber'] = `${indexGroup + 1}.${indexItem + 1}`;
        if (this.enumerators.Signaling[deviceItem.signaling].toLocaleLowerCase() == item.type.toLocaleLowerCase()) {
          itemsContract.push(deviceItem);
        } else {
          itemsContractNotFound.push(deviceItem);
        }
      });
    });
    return { itemsContract, itemsContractNotFound };
  }

  private similarityPercentage(str1: string, str2: string): number {
    const words1 = new Set(this.cleanText(str1));
    const words2 = new Set(this.cleanText(str2));
    const commonWords = Array.from(words1).filter((word) => words2.has(word)).length;
    const similarity = (commonWords / words1.size) * 100;
    return Math.floor(similarity);
  }

  private cleanText(text: string): string[] {
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w\s]/g, '')
      .split(/\s+/);
  }

  createProjectItems() {
    this.processVerticalItems();
    this.processHorizontalItems();
    this.processSupportItems();
    this.processDeviceItems();

    this.projectImportType = 'vertical';
    this.closeModal();
  }

  createActivieList({ type, quantity, latitude, longitude, availableCodes }) {
    let activitiesList = [];

    for (let index = 0; index < quantity; index++) {
      const code = availableCodes[index] || undefined;
      activitiesList?.push({ signCode: type, latitude, longitude, latlngForm: '', code })
    }
    return activitiesList;
  }

  private processVerticalItems() {
    const itemCounts: { [key: string]: any } = {};
    const typeCodeMap: { [key: string]: string[] } = {};
    this.resultProjectImport.vertical.forEach((item, index) => {
      const key = `${item.type}_${item.contractItemId}`; // Cria uma chave única com base no tipo e ID do contrato
      if (!itemCounts[key]) {
        itemCounts[key] = {
          ...item,
          quantity: 0, // Inicializa a quantidade
        };
      }
      itemCounts[key].quantity++; // Incrementa a quantidade

      if (!typeCodeMap[key]) {
        typeCodeMap[key] = [];
      }
      typeCodeMap[key].push(item.code); // Armazena os códigos associados ao tipo
    });

    // Converte o objeto de contagem em uma lista
    const newList: any = Object.values(itemCounts).map(function (item) {
      let activitiesList = [];
      const typeKey = `${item.type}_${item.contractItemId}`;
      const availableCodes = typeCodeMap[typeKey] || []; // Lista de códigos disponíveis para o tipo
      activitiesList = this.createActivieList({ type: item.type, quantity: item.quantity, latitude: undefined, longitude: undefined, availableCodes })

      return new ProjectTrafficSign({
        contractVerticamItem: item.contractItem,
        contractVerticalItemId: item.contractItemId,
        dimension: this.convertToNumber(item.dimension),
        dimensionX: this.convertToNumber(item.dimensionX),
        dimensionY: this.convertToNumber(item.dimensionY),
        verticalCode: item.type,
        verticalType: this.setVerticalClass(item.type),
        signCode: item.type,
        warranty: this.getWarranty(item),
        quantity: item.quantity,
        activitiesList: activitiesList,
      });
    }.bind(this)); // vincula o contexto 'this' à função
    // Adiciona a nova lista ao projeto
    this.project.projectTrafficSigns.push(...newList);
  }

  private processHorizontalItems() {
    const itemCounts: { [key: string]: any } = {};
    const typeCodeMap: { [key: string]: string[] } = {};

    this.resultProjectImport.horizontal.forEach((item) => {
      const key = `${item.type}_${item.contractItemId}`; // Cria uma chave única com base no tipo e ID do contrato
      if (!itemCounts[key]) {
        itemCounts[key] = {
          ...item,
          quantity: 0, // Inicializa a quantidade
        };
      }
      itemCounts[key].quantity++; // Incrementa a quantidade

      if (!typeCodeMap[key]) {
        typeCodeMap[key] = [];
      }

      typeCodeMap[key].push(item.code); // Armazena os códigos associados ao tipo

    });

    const newList: any = Object.values(itemCounts).map(function (item) {
      let activitiesList = [];
      const typeKey = `${item.type}_${item.contractItemId}`;
      const availableCodes = typeCodeMap[typeKey] || []; // Lista de códigos disponíveis para o tipo
      activitiesList = this.createActivieList({ type: item.type, quantity: item.quantity, latitude: undefined, longitude: undefined, availableCodes })

      return new ProjectHorizontalSignaling({
        warranty: this.getWarranty(item),
        contractHorizontalItem: item.contractItem,
        contractHorizontalItemId: item.contractItemId,
        dimension: this.convertToNumber(item.dimension),
        dimensionX: this.convertToNumber(item.dimensionX),
        dimensionY: this.convertToNumber(item.dimensionY),
        horizontalCode: item.type,
        signCode: item.type,
        quantity: item.quantity,
        activitiesList
      });
    }.bind(this)); // vincula o contexto 'this' à função

    // Adiciona a nova lista ao projeto
    this.project.projectHorizontalSignalings.push(...newList);
  }

  private processSupportItems() {
    this.resultProjectImport.support.forEach((element) => {
      if (!element.check) {
        this.project.projectSupports.push(new ProjectSupport({ warranty: new Date() }));
      }
    });
  }

  private processDeviceItems() {
    this.resultProjectImport.device.forEach((element) => {
      this.project.projectDeviceSignalings.push(new ProjectDeviceSignaling({ warranty: new Date() }));
    });
  }

  createProjectActivieList() {
    this.project.projectHorizontalSignalings.forEach(element => {
      element.activitiesList = this.createActivieList({ type: element.signCode, quantity: element.quantity, latitude: element.latitude, longitude: element.longitude, availableCodes: [] });
    });

    this.project.projectTrafficSigns.forEach(element => {
      element.activitiesList = this.createActivieList({ type: element.signCode, quantity: element.quantity, latitude: element.latitude, longitude: element.longitude, availableCodes: [] });
    });

    this.project.projectDeviceSignalings.forEach(element => {
      element.activitiesList = this.createActivieList({ type: element.signCode, quantity: 2, latitude: undefined, longitude: undefined, availableCodes: [] });
    });
  }

  setVerticalClass(code): void {
    let verticalType;

    if (this.verticalSigns.indication.includes(code)) {
      verticalType = VerticalSignalizationTypeEnum.Indication;
    } else if (this.verticalSigns.warning.includes(code)) {
      verticalType = VerticalSignalizationTypeEnum.Warning;
    } else if (this.verticalSigns.regulation.includes(code)) {
      verticalType = VerticalSignalizationTypeEnum.Regulation;
    }
    return verticalType;
  }

  getWarranty(item) {
    let warranty;

    if (item?.contractItem?.warrantyType == WarrantyTypeEnum.TimeInterval) warranty = add(new Date(), { years: item.contractItem.warrantyYear, months: item.contractItem.warrantyMonth })
    else warranty = new Date(item?.contractItem?.warranty || new Date());

    return warranty
  }

  clearProject() {
    this.project.projectHorizontalSignalings = [];
    this.project.projectDeviceSignalings = [];
    this.project.projectSupports = [];
    this.project.projectTrafficSigns = [];
  }

  convertToNumber(value) {
    if (typeof value === 'string') {
      // Remove pontos e converte vírgula para ponto
      return Number(value.replace(/\./g, '').replace(',', '.'));
    } else if (typeof value === 'number') {
      return value;
    }
  }
}
