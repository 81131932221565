import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterService } from 'src/app/services/filter.service';
import { enumerators } from 'src/dictionaries/enum.pt-br';
import { DelimitationHorizontalSignalingEnum, DeviceSignalingEnum, IndicationVerticalEnum, LongitudinalHorizontalSignalingEnum, MarkingTypeEnum, PavementHorizontalSignalingEnum, PlumbingHorizontalSignalingEnum, PositioningArrowsEnum, RegulationVerticalEnum, SignalingTypeEnum, SpecialHorizontalEnum, SpecialVerticalEnum, TransversalsHorizontalSignalingEnum, VerticalSignalizationTypeEnum, WarningVerticalEnum } from 'src/enumerators';

@Component({
  selector: 'app-sign-code-modal',
  templateUrl: './sign-code-modal.component.html',
  styleUrls: ['./sign-code-modal.component.css']
})
export class SignCodeModalComponent implements OnInit {

  @Input() code;
  @Input() signType: SignalingTypeEnum;
  @Output() emitCode = new EventEmitter<any>();
  @Output() shareCloseModal = new EventEmitter<any>();

  currentCode; // Code selecionado
  signInput = ''; // Input para pesquisa, serve como filtro
  classModalSelect; // Classe selecionada, serve como filtro

  verticalTypeEnum = VerticalSignalizationTypeEnum; // Enum de classes de verticais
  verticalSignalings = {
    warning: Object.values(WarningVerticalEnum),
    regulation: Object.values(RegulationVerticalEnum),
    indication: Object.values(IndicationVerticalEnum),
    special: Object.values(SpecialVerticalEnum),
  }; // Os enums de vertical separado por classe
  verticalTypes = Object.values(VerticalSignalizationTypeEnum); // As classes de vertical

  markTypeEnum = MarkingTypeEnum; // Enum de marks de horizontal
  horizontalSignalings = {
    longitudinal: Object.values(LongitudinalHorizontalSignalingEnum),
    transversal: Object.values(TransversalsHorizontalSignalingEnum),
    channeling: Object.values(PlumbingHorizontalSignalingEnum),
    delimitation: Object.values(DelimitationHorizontalSignalingEnum),
    inscriptions: Object.values(PavementHorizontalSignalingEnum),
    positioning: Object.values(PositioningArrowsEnum),
    special: Object.values(SpecialHorizontalEnum),
  }; // Os enums de horizontal separado por classe
  markinTypes = Object.values(MarkingTypeEnum); // As classes de horizontal

  deviceSignalings = Object.values(DeviceSignalingEnum); // Os enums de dispositivos

  typeSignEnum = SignalingTypeEnum;

  constructor(
    private _modalService: BsModalService,
    private _filterService: FilterService,
  ) { }

  ngOnInit(): void {

  }

  emitSignCode() {
    this.emitCode.emit(this.currentCode)
  }

  changeCode(code) {
    this.currentCode = code;
  }

  filterModalSigns(list: Array<any>): Array<any> {
    return this._filterService.filterListInput(list, this.signInput, enumerators.Signaling);
  }

  // Fecha o último modal aberto
  closeModal() {
    this.shareCloseModal.emit();
  }

  // Define a classe escolhida, clica novamente para reiniciar
  selectClass(type) {
    if (this.classModalSelect == type) {
      this.classModalSelect = undefined;
    } else {
      this.classModalSelect = type;
    }
  }
}
