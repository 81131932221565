<!-- Template Modal Selecionar Sinalização Filtro-->
<div class="modal-header" style="align-items: center;">
  <h3 class="modal-title">Selecione a sinalização que deseja cadastrar</h3>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <i aria-hidden="true" class="fas fa-xmark"></i>
  </button>
</div>

<div class="modal-body">

  <div class="col-12">
    <div class="form-group mb-5">
      <input type="text" class="form-search form-control" autocomplete="off" placeholder="Procure pela sinalização aqui"
        [(ngModel)]="signInput">
    </div>
  </div>

  <div class="d-flex justify-content-between">
    <!-- Botões de filtro -->
    <div class="col-3 pr-4">
      <div *ngIf="signType == typeSignEnum.Horizontal">
        <div *ngFor="let type of markinTypes">
          <div class="row m-0 pb-4">
            <button class="button-sign" (click)="selectClass(type)" [ngClass]="{'active': classModalSelect == type}">
              <div class="d-flex align-items-start flex-column">
                <p class="p-info">{{type | enumText: 'HorizontalType'}}</p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="signType == typeSignEnum.Vertical">
        <div *ngFor="let type of verticalTypes">
          <div class="row m-0 pb-4">
            <button class="button-sign" (click)="selectClass(type)" [ngClass]="{'active': classModalSelect == type}">
              <div class="d-flex align-items-start flex-column">
                <p class="p-info">{{type | enumText: 'SignalizationType'}}</p>
              </div>
            </button>
          </div>
        </div>
      </div>

    </div>
    <!-- Botões de filtro -->

    <!-- Sinalizações do filtro -->
    <div class="col-9 list-signs">
      <ng-container>
        <!-- VERTICAL -->
        <ng-container *ngIf="signType == typeSignEnum.Vertical">
          <ng-container *ngIf="classModalSelect == verticalTypeEnum.Warning || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(verticalSignalings['warning'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode) }">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType"
                  style="height: 64px; object-fit: contain;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == verticalTypeEnum.Regulation || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(verticalSignalings['regulation'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode) }">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType"
                  style="height: 64px; object-fit: contain;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == verticalTypeEnum.Indication || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(verticalSignalings['indication'])">
              <div [title]="sign|enumText: 'Signaling'" class="select-sign col-30" (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode) }">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType"
                  style="height: 64px; object-fit: contain;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == verticalTypeEnum.Special">
            <ng-container *ngFor="let sign of filterModalSigns(verticalSignalings['special'])">
              <div [title]="sign|enumText: 'Signaling'" class="select-sign col-30" (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode) }">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType"
                  style="height: 64px; object-fit: contain;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- VERTICAL -->

        <!-- HORIZONTAL -->
        <ng-container *ngIf="signType == typeSignEnum.Horizontal">
          <ng-container *ngIf="classModalSelect == markTypeEnum.longitudinal || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['longitudinal'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.transversal || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['transversal'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode)}">

                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.channeling || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['channeling'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.delimitation || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['delimitation'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.inscriptions || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['inscriptions'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.positioningArrows || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['positioning'])">
              <div [title]="(sign|enumText: 'Signaling').split(' - ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.special || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['special'])">
              <div [title]="(sign|enumText: 'Signaling').split(' - ')[1]" class="select-sign col-30"
                (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
                || ((code == sign) && !currentCode)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

        </ng-container>
        <!-- HORIZONTAL -->

        <!-- DISPOSITIVO -->
        <ng-container *ngIf="signType == typeSignEnum.Device">
          <ng-container *ngFor="let sign of filterModalSigns(deviceSignalings)">
            <div *ngIf="filterContractDevice(sign)" [title]="sign|enumText: 'Signaling'" class="select-sign col-30"
              (click)="currentCode=sign" [ngClass]="{'active': (sign == currentCode && (currentCode != code))
              || ((code == sign) && !currentCode)}">
              <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
              <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
            </div>
          </ng-container>
        </ng-container>
        <!-- DISPOSITIVO -->
      </ng-container>
    </div>

    <!-- Sinalizações do filtro -->
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" style="margin:0em;" (click)="closeModal();">Cancelar</button>
  <button [disabled]="!currentCode" type="button" class="btn btn-primary"
    (click)="emitSignCode(); closeModal();">SELECIONAR</button>
</div>

<!-- Template Modal Selecionar Sinalização Filtro-->
