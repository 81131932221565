import { Injectable } from '@angular/core';
import { SignalingTypeEnum } from 'src/enumerators';
import { PolygonService } from './polygon.service';
import { startOfDay, endOfDay } from 'date-fns';
@Injectable({
  providedIn: 'root'
})

export class FilterService {

  checkOptionsFilter = []; // Essa varáivel gigante é pra cuidar do filtro, cada um é pra um tipo, que é representado no value
  signalingsTypes: Array<SignalingTypeEnum> = []; // Filtro de HVD da esquerda na lista

  constructor(
    private _polygonService: PolygonService
  ) { }

  // Filtra a partir dos parâmetros passados
  filterItems(searches: any, signs: Array<any>) {
    if (searches.length == 0) { return signs; } // Busca Nula, Retorna os Itens
    if (searches.length == 1 && searches[0].searchText == 'empty') { return []; }
    let tempList = new Set(signs);
    // Listas para fazer interseção no final
    let tempResultBoolean = [];
    let tempResultNumber = [];

    // Varrer Todo o Filtro Recebido Para Realizar a Busca Individual
    searches.forEach(search => {
      if (search.searchAttribute != 'empty') {
        // Definir o Valor da Busca Baseado no Tipo
        const searchAttribute = search.searchAttribute;
        let searchText = null;
        switch (typeof (search.searchText)) {
          case 'boolean':
            searchText = true;
            break;
          case 'number':
            searchText = search.searchText;
            break;
        }

        // Se o 'searchText' For Boleano, retorna lista com filtro, ela é uma lista adicional, pra cada pesquisa ela retira ou adiciona o filtro boleano que já foi passado
        if (searchText === true) {
          const tempResultAux = Array.from(signs).filter(object => {
            if (object.type != undefined) {
              return object.type == searchAttribute;
            }
          });
          tempResultAux.forEach(element => {
            if (tempResultBoolean.indexOf(element) == -1) {
              tempResultBoolean.push(element);
            }
          });
        } else {
          // Se o 'searchText' For Número, retorna lista com filtro, sempre uma nova
          if (typeof (search.searchText) === 'number') {

            tempResultNumber = Array.from(signs).filter(object => {
              return object[searchAttribute] == searchText;
            });
          }
        }
        let tempResult = signs;
        searches.forEach(search => {
          if (search.searchAttribute != 'empty') {
            if (search.searchAttribute == 'active') {
              tempResult = this.intersection(tempResult, tempResultNumber);
            } else {
              tempResult = this.intersection(tempResult, tempResultBoolean);
            }
          }
        });
        // Adicionar Resultado do Filtro à Lista Final
        tempList = new Set();
        tempResult.forEach(filterItem => {
          tempList.add(filterItem);
        });
      }
    });
    return Array.from(tempList);
  }

  // Filtra as opções no Mapa de Sinalizações e de Tarefas, signTask = false é Sinalização signTask = true é tarefas
  filterItemsOptions(filterOptions, itemsList, signTask = true) {
    const filterList: Array<any> = [];
    let listBool: Array<boolean> = [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true];
    let latlng = [];
    let result;
    let findSign = false;
    itemsList.forEach(item => {
      findSign = false;  // Como a sinalização tem 3 tipos, eu fiz essa variavel pra se achar ela nao vai continuar nos outros tipos
      listBool = [true, true, true, true, true, true, true, true, true, true, true];

      filterOptions.forEach((option, i) => {
        option.selected.some(select => {
          switch (option.value) {
            case 'Contrato':
              if (select == item.contractId) {
                listBool[0] = true;
                return true;
              }
              listBool[0] = false;
              break;

            case 'Vínculo Secundário':
              if (select == item.projectId) {
                listBool[1] = true;
                return true;
              }
              listBool[1] = false;
              break;

            case 'Região':
              latlng = [];
              if (!signTask) {
                latlng = [item.latitude, item.longitude];
              }
              else if (item.type == SignalingTypeEnum.Vertical) {
                latlng = [item.lat, item.lng];
              } else {
                latlng = [item.locations[0].lat, item.locations[0].lng];
              }

              result = this._polygonService.pointInPolygon({ lat: latlng[0], lng: latlng[1] }, filterOptions[i].map[select]);
              if (result) {
                listBool[2] = true;
                return true;
              }
              listBool[2] = false;
              break;

            case 'Área':
              latlng = [];
              if (!signTask) {
                latlng = [item?.latitude, item?.longitude];
              }
              else if (item.type == SignalingTypeEnum.Vertical) {
                latlng = [item?.lat, item?.lng];
              } else {
                latlng = [item?.locations[0]?.lat, item?.locations[0]?.lng];
              }

              result = this._polygonService.pointInPolygon({ lat: latlng[0], lng: latlng[1] }, filterOptions[i].map[select]);
              if (result) {
                listBool[3] = true;
                return true;
              }
              listBool[3] = false;
              break;

            case 'Intervalo de Tempo':
              if (select.length > 0) {
                const startDate = startOfDay(new Date(new Date(select[0])));
                const endDate = endOfDay(new Date(new Date(select[1])));
                if (signTask && startDate < new Date(item?.createdAt) && endDate > new Date(item?.createdAt)) {
                  listBool[4] = true;
                  return true;
                } else if (!signTask && startDate < new Date(item?.executionDate) && endDate > new Date(item?.endDate)) {
                  listBool[4] = true;
                  return true;
                }
                listBool[4] = false;
              }
              break;

            case 'Responsável':
              if (signTask && select == item?.createdByMemberId) {
                listBool[5] = true;
                return true;
              }
              else if (!signTask && select == item?.responsibleMemberId) {
                listBool[5] = true;
                return true;
              }
              listBool[5] = false;
              break;

            case 'Sinalização':
              if (!findSign && !signTask) {

                if (item.signalingType == SignalingTypeEnum.Vertical) {
                  if (select == item?.trafficSignTaskActivities[0]?.verticalCode) {
                    listBool[6] = true;
                    findSign = true;
                    return true;
                  }

                } else if (item.signalingType == SignalingTypeEnum.Horizontal) {
                  if (select == item?.horizontalSignalingTaskActivities[0]?.horizontalCode) {
                    listBool[6] = true;
                    findSign = true;
                    return true;
                  }

                } else {
                  if (select == item?.deviceSignalingTaskActivities[0]?.deviceCode) {
                    listBool[6] = true;
                    findSign = true;
                    return true;
                  }
                }
                listBool[6] = false;
              }
              else if (!findSign && signTask) {
                const code = 'signCode';
                if (select == item[code]) {
                  listBool[6] = true;
                  findSign = true;
                  return true;
                }
                listBool[6] = false;
              }
              break;

            case 'Prioridade':
              if (!signTask) {
                if (select == item?.priority) {
                  listBool[7] = true;
                  return true;
                }
              }
              listBool[7] = false;
              break

            case 'Status':
              if (!signTask) {
                if (select == item?.status) {
                  listBool[8] = true;
                  return true;
                }
              }
              listBool[8] = false;
              break

            case 'Líder de Equipe':
              if (!signTask) {
                if (select == item?.team?.leaderMemberId) {
                  listBool[9] = true;
                  return true;
                }
              }
              listBool[9] = false;
              break

            case 'Veículo':
              if (!signTask) {
                if (select == item?.vehicleId) {
                  listBool[10] = true;
                  return true;
                }
              }
              listBool[10] = false;
              break

            case 'Endereço':
              if (select == item?.address?.road || select == item?.address?.suburb) {
                listBool[11] = true;
                return true;
              }
              listBool[11] = false;
              break

            case 'Intervalo de Tempo  de Manutenção':
              if (!signTask && select.length > 0) {
                const startDate = startOfDay(new Date(new Date(select[0])));
                const endDate = endOfDay(new Date(new Date(select[1])));
                if (startDate < new Date(item?.lastMaintenanceDate) && endDate > new Date(item?.lastMaintenanceDate)) {
                  listBool[12] = true;
                  return true;
                }
                listBool[12] = false;
              }
              break;

            case 'Itens do Contrato de Vertical':
              if (!signTask) {
                if (select?.groupType == 'ROAD_SIGN' && select == item?.contractVerticalItemId) {
                  listBool[13] = true;
                  return true;
                }
                else if (select?.groupType == 'SUPPORT' && select == item?.contractSupportItemId) {
                  listBool[13] = true;
                  return true;
                } {

                }
              }
              listBool[13] = false;
              break

            case 'Itens do Contrato de Horizontal':
              if (!signTask && select?.groupType == 'HORIZONTAL') {
                if (select == item?.contractHorizontalItemId) {
                  listBool[14] = true;
                  return true;
                }
              }
              listBool[14] = false;
              break

            case 'Itens do Contrato de Dispositivo':
              if (!signTask && select?.groupType == 'DEVICE') {
                if (select == item?.contractDeviceItemId) {
                  listBool[15] = true;
                  return true;
                }
              }
              listBool[15] = false;
              break
            default:
              break;
          }
        });
      });
      const find = listBool.findIndex(bool => bool == false);

      if (find == -1) {
        filterList.push(item);
      }
    });
    return filterList;
  }

  // Filtra uma lista, Array<any> a partir de um input string, caso o input vazio retorna lista completa.
  // Verifica se o item da lista está incluí o input
  // option caso tenha um enumerador, exemplo enumerador de sinalização, passar o enumerators.Signaling
  // para filtrar a partir do nome completo da sinalização e não apenas o enum
  filterListInput(list: Array<any>, input: string, optionEnum?): Array<any> {
    if (list && input) {
      let newList = [];
      newList = list.filter(sign => {
        if (optionEnum) {
          return optionEnum[sign].toUpperCase().includes(input.toUpperCase());
        } else {
          return sign.toUpperCase().includes(input.toUpperCase());
        }
      });
      return newList;
    }
    return list;
  }

  // Interseção entre duas listas
  intersection(array1: Array<any>, array2: Array<any>) {
    let filteredArray;
    filteredArray = array1.filter(value => array2.includes(value));

    return filteredArray;
  }
}
