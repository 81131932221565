<div class="row px-2 header-container justify-content-between">
  <div class="col-2 navbar-brand">
    <a routerLink="/">
      <img [alt]="environment.softwareName" class="logo" [src]="'assets/icons/logo-hellius.svg'"
        style="cursor: pointer" />
    </a>
  </div>
  <div class="col-auto header-nav">
    <!-- DROPDOWN NOTIFICAÇÃO -->
    <div class="nav-notification mr-5 position-relative" dropdown>
      <i dropdownToggle class="user-img fa-regular fa-bell fa-lg dropdown-toggle cursor-pointer"
        (click)="socketService.showNewLog = false"></i>
      <i *ngIf="socketService.showNewLog" class="fa-solid fa-circle"
        style="font-size: 8px; position: absolute; top: 0; color: var(--bluemix-light)"></i>
      <div id="dropdown-notification" *dropdownMenu class="mt-4 ml-2 dropdown-menu dropdown-menu-right log-drop p-0"
        role="menu">
        <div class="d-flex justify-content-between align-items-center px-4 pt-3 pb-2" role="menuitem">
          <h3 style="pointer-events: none; border-radius: 8px">Notificações</h3>
          <i (click)="dropdown.hide()" class="fa-regular fa-xmark fa-lg cursor-pointer"></i>
        </div>
        <div class="divider dropdown-divider m-0"></div>
        <div style="overflow: auto; max-height: 640px">
          <ng-container *ngIf="
              socketService?.listGroupNotificationsDays.length > 0 || socketService?.listNotificationsDays.length > 0;
              else emptyLogsList
            ">
            <div *ngFor="
                let notification of socketService?.listGroupNotificationsDays.length > 0
                  ? socketService?.listGroupNotificationsDays
                  : socketService?.listNotificationsDays
              " role="menuitem" class="cursor-pointer px-4 pt-4 pb-3">
              <div>
                <p class="ml-2 mb-2" style="font-size: 12px">{{ notification[0] }}</p>
              </div>
              <div *ngFor="let log of notification[1]" role="menuitem"
                class="d-flex justify-content-between align-items-center log-line px-3 mt-2 menu-item dropdown-item"
                style="white-space: normal !important; height: 37px" (click)="signDetailRouting(log[0])">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <img *ngIf="log[0].module != systemModule.Warranty" width="24px" height="24px"
                    style="border-radius: 50%"
                    [src]="log[0]?.user?.imageURL ? log[0].user.imageURL : 'assets/icons/camera.svg'" class="mr-1" />
                  <div class="circle-exclamation mr-3" *ngIf="log[0]?.module == systemModule.Warranty"
                    style="min-height: 28px; min-width: 28px">
                    <i class="fa-solid fa-exclamation text-white" style="font-size: 16px"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <p style="color: var(--gray-base); font-size: 12px; max-width: 252px; line-height: 18px"
                      class="m-0 ml-1">
                      {{ log[1] }}
                    </p>
                    <p style="color: var(--gray-base); font-size: 14px" class="my-0 ml-1">
                      {{ log[0]?.createdAt | date: 'HH:mm' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #emptyLogsList>
            <div class="d-flex align-items-center justify-content-center flex-column h-100">
              <img src="assets/img/logEmpty.svg" style="height: 194px; width: 270px; margin-bottom: 40px" />
              <h3 class="log-empty">
                Você não tem novas notificações.<br />
                Para ver suas notificações, clique em Ver Mais.
              </h3>
            </div>
          </ng-template>
        </div>
        <div class="divider dropdown-divider m-0"></div>
        <div role="menuitem" class="d-flex justify-content-center align-items-center" style="height: 60px">
          <a class="cursor-pointer" style="border-radius: 8px; color: var(--blue-base)" href="#/settings/logs">
            Ver mais
          </a>
        </div>
      </div>
    </div>
    <!-- DROPDOWN NOTIFICAÇÃO -->

    <!-- DROPDOWN ORGANIZAÇÃO -->
    <div class="nav-notification mr-5 position-relative" dropdown>
      <img dropdownToggle width="36px" height="36px" style="border-radius: 50%"
        [src]="organizationService.currentWorkspace?.imageURL || 'assets/org-mono.svg'" class="cursor-pointer" />

      <div id="dropdown-org" *dropdownMenu class="mt-4 ml-2 dropdown-menu dropdown-menu-right log-drop p-0"
        style="width: 544px" role="menu">
        <div class="d-flex flex-column p-3" style="max-height: 600px ;overflow: auto; gap: 16px">
          <div *ngFor="let workspace of organizationService?.listWorkspaces" role="menuitem"
            class="d-flex align-items-center justify-content-between log-line pl-2 pr-3 menu-item dropdown-item"
            style="white-space: normal !important; height: 72px">
            <div class="d-flex" style="gap: 24px">
              <div class="d-flex justify-content-between align-items-center position-relative">
                <img width="56px" height="56px" style="border-radius: 50%"
                  [src]="workspace?.imageURL || 'assets/org-mono.svg'" class="mr-1" />
                <i *ngIf="workspace?.id == organizationService?.currentWorkspace?.id"
                  class="fas fa-circle-check fa-lg position-absolute"
                  style="color: var(--greenmix-dark); bottom: 10px; right: -5px"></i>
              </div>
              <div class="d-flex flex-column">
                <h3>{{ workspace.name }}</h3>
                <span class="name-duty">
                  {{ authService?.loggedUser?.firstName + ' ' + authService?.loggedUser?.lastName }} •
                  {{ workspace?.memberRole | enumText: 'UserPermission' }}</span>
              </div>
            </div>
            <p *ngIf="workspace.id != organizationService?.currentWorkspace?.id"
              class="link cursor-pointer m-0 button-dark" style="color: var(--blue-base)"
              (click)="currentWorkspace = workspace; openModal(switchOrgModal)">
              Trocar
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- DROPDOWN ORGANIZAÇÃO -->

    <!-- DROPDOWN PERFIL -->
    <div class="nav-profile position-relative" dropdown>
      <img dropdownToggle alt="profile-example" class="user-img dropdown-toggle cursor-pointer"
        [src]="authService?.loggedUser?.imageURL ? authService?.loggedUser?.imageURL : 'assets/icons/camera.svg'"
        style="border-radius: 50px; height: 35px; width: 35px" />
      <ul id="dropdown-logout" *dropdownMenu class="dropdown-menu dropdown-menu-right p-3 user-drop" role="menu"
        aria-labelledby="button-basic">
        <!-- <li role="menuitem"><a class="dropdown-item p-3" style="color: var(--gray-dark);">
          <i class="fas fa-file-export mr-3" style="font-size: 20px; width: 20px;"></i>Exportar</a></li> -->
        <li role="menuitem">
          <a class="dropdown-item p-3 pointer menu-item" style="border-radius: 8px; height: 48px" href="#/profile">
            Perfil</a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item p-3 pointer menu-item" style="border-radius: 8px; height: 48px" href="#/organization">
            Organização</a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item p-3 pointer menu-item" style="border-radius: 8px; height: 48px" href="#/contracts"
            *ngIf="authorizationService.checkMemberRole([memberRoleEnum.Manager, memberRoleEnum.Operator])">
            Contratos</a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item p-3 pointer menu-item" style="border-radius: 8px; height: 48px" href="#/settings">
            Configurações</a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item p-3 pointer menu-item" style="border-radius: 8px; height: 48px" (click)="logout()">
            Log out</a>
        </li>
      </ul>
    </div>
    <!-- DROPDOWN PERFIL -->
  </div>
</div>

<ng-template #switchOrgModal>
  <div class="modal-header" style="align-items: center">
    <h3 class="modal-title">Trocar de Organização</h3>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <i aria-hidden="true" class="fas fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-bottom: 32px">
      <h6>Tem certeza que deseja entrar na organização "{{ currentWorkspace.name }}"?</h6>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-primary" (click)="closeModal()">
      <h4 style="font-weight: 600">CANCELAR</h4>
    </button>
    <button class="btn btn-primary mr-2" (click)="switchWorkspace(currentWorkspace.id); closeModal()">
      <h4 style="font-weight: 600">TROCAR</h4>
    </button>
  </div>
</ng-template>
