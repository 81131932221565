<!-- Formaulario Horizontal -->
<ng-container *ngFor="let sign of trafficSignTaskActivities; index as indexOfSign">
  <accordion [isAnimated]="true" [closeOthers]="true">
    <accordion-group [isOpen]="sign?.isAccOpen">
      <div class="row mx-0 w-100" style="cursor: auto;" accordion-heading>
        <div class="col d-flex sign-container mb-4 w-100">

          <!-- SVG da Sinalização -->
          <div class="svg-collapse">
            <div *ngIf="!sign.complementInformationType" class="position-relative" (click)="openAccordionGroup(sign)">
              <img
                [src]="sign.verticalCode ?  (sign.verticalCode| enumSvg: 'Signaling': signalingTypeEnum.Vertical) : 'assets/icons/v-sign-task-icon.svg'"
                style="width: 48px; height: 48px; object-fit: contain;"
                [ngClass]="{'sign-cam-40': sign?.verticalCode == verticalSignEnum.ViaFiscalizadaPorCameras}">
            </div>
            <div *ngIf="sign?.complementInformationType" class="d-flex justify-content-center position-relative"
              style="height: 60px; width: 40px;">
              <img *ngIf="sign?.complementInformationType == complementInformationType.Additional"
                [src]="complementType(sign)| enumSvg: 'ComplementInformation'"
                style="width: 40px; height: 20px; position: absolute; bottom: 0px;">
              <img *ngIf="sign?.complementInformationType == complementInformationType.Incorporated"
                [src]="complementType(sign)| enumSvg: 'ComplementInformation'"
                style="width: 40px; height: 60px; position: absolute;">
              <div [ngClass]="sign?.verticalCode| enumCaption: 'Caption' : 32"
                class="position-relative internal-caption" style="height: 32px; width: 32px; top: 5px">
                <span class="position-absolute" style="z-index: 1;">{{sign?.verticalType !=
                  verticalSignalizationType?.Indication ? sign?.internalCaption : ''}}</span>
                <img
                  [src]="sign?.verticalCode ? (sign?.verticalCode| enumSvg: 'Signaling': signType) : 'assets/icons/v-sign-task-icon.svg'"
                  style="width: 32px; height: 32px; position: absolute; object-fit: contain;">
              </div>
            </div>
          </div>
          <!-- SVG da Sinalização -->


          <!-- Nome da Sinalização -->
          <div class="col mt-2 cursor-pointer" *ngIf="!sign?.isAccOpen" (click)="openAccordionGroup(sign)">
            <div class="ml-2">
              <h4 class="item-title">Sinalização selecionada</h4>
              <p>{{sign.verticalCode ? (sign?.verticalCode|enumText: 'Signaling') : 'Selecione a Sinalização'}}</p>
            </div>
          </div>
          <!-- Nome da Sinalização -->


          <!-- Formulário da Sinalização -->
          <div class="col mt-3" *ngIf="sign?.isAccOpen">
            <div class="content-contractual">
              <form #vertical="ngForm">
                <div class="form-group" *ngIf="isStock || (!isStock && !stockEntries?.length)">
                  <label for="inputSignal">Selecione o item do Contrato</label>
                  <select name="contractBond" class="form-control" [(ngModel)]="sign.contractVerticalItemId" required
                    autocomplete="off" list="contractItems"
                    (change)="sign.contractVerticalItemId = $event.target.value;setContractItem(sign); emitValidation()">
                    <option value="" hidden>Selecione...</option>
                    <option *ngFor="let item of verticalItems" [value]="item.id">{{item?.name}}</option>
                  </select>
                </div>
                <div class="snow-light-container mb-3" *ngIf="stockEntries?.length">
                  <div class="row mx-0 d-flex align-items-center justify-content-between">
                    <div class="col custom-control custom-radio mb-2">
                      <input type="radio" name="contractOrStockRadio{{indexOfSign+1}}"
                        id="contractOrStockRadio{{indexOfSign+1}}1" class="custom-control-input cursor-pointer"
                        (change)="setBond('contract', indexOfSign)" [checked]="radioState[indexOfSign]?.contract">
                      <label class="custom-control-label pl-2" style="font-size: 16px;"
                        for="contractOrStockRadio{{indexOfSign+1}}1">Item do
                        Contrato</label>
                    </div>
                    <div class="col custom-control custom-radio mb-2">
                      <input type="radio" name="contractOrStockRadio{{indexOfSign+1}}"
                        id="contractOrStockRadio{{indexOfSign+1}}2" class="custom-control-input cursor-pointer"
                        (change)="setBond('stock', indexOfSign)" [checked]="radioState[indexOfSign]?.stock">
                      <label class="custom-control-label pl-2" style="font-size: 16px;"
                        for="contractOrStockRadio{{indexOfSign+1}}2">Vínculo
                        com Estoque</label>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="radioState[indexOfSign]?.contract">
                    <label for="contractBond">Selecione o item do Contrato</label>
                    <select name="contractBond" class="form-control" [(ngModel)]="sign.contractVerticalItemId" required
                      autocomplete="off" list="contractItems"
                      (change)="sign.contractVerticalItemId = $event.target.value;setContractItem(sign); emitValidation()">
                      <option value="" hidden>Selecione...</option>
                      <option *ngFor="let item of verticalItems" [value]="item.id">{{item?.name}}</option>
                    </select>
                  </div>
                  <div class="form-group" *ngIf="radioState[indexOfSign]?.stock">
                    <label for="stockedTrafficSignId">Selecione o item do Estoque</label>
                    <select name="stockedTrafficSignId" class="form-control" [(ngModel)]="sign.stockedTrafficSignId"
                      list="stockList" required
                      (change)="setContractItem(sign, $event.target.value, indexOfSign); emitValidation()">
                      <option [value]="undefined" hidden>Selecione...</option>
                      <option *ngFor="let item of stockEntries" [value]="item.id">
                        {{item.contractVerticalItem.name+ ' - '+(item.quantity - item.quantityRegistered)+'
                        disponíveis'}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="snow-light-container mb-3">
                  <div class="row mx-0">
                    <div class="col-2 px-0">
                      <div class="form-group">
                        <label for="quantity">Quantidade</label>
                        <input name="quantity" [(ngModel)]="sign.quantity" required class="form-control" type="number"
                          placeholder="0" min="0" oninput="if(this.value < 0) this.value = 0"
                          (input)="emitValidation(); calculateDimension(sign); remakeActivitieList(sign)" [dropSpecialCharacters]="false">
                      </div>
                    </div>
                    <!-- Sinalização Com Modal -->
                    <ng-container>
                      <div class="d-flex align-items-center justify-content-between col-10 mb-2">
                        <div class="d-flex align-items-center w-100 ml-3" style="gap: 32px">
                          <img
                            [src]="sign.verticalCode ? (sign.verticalCode| enumSvg: 'Signaling': signalingTypeEnum.Vertical) : 'assets/icons/v-sign-task-icon.svg'"
                            alt="" style="object-fit: contain;" height="48px" width="48px"
                            [ngClass]="{'sign-cam-40': sign?.verticalCode == verticalSignEnum?.ViaFiscalizadaPorCameras}">

                          <span style="color: var(--gray-base);">
                            {{sign.verticalCode
                            ? (sign.verticalCode|enumText:'Signaling')
                            : 'Selecione a sinalização a ser cadastrada.'}}
                          </span>
                        </div>

                        <button class="btn-popup button-sign p-0" *ngIf="!radioState[indexOfSign]?.stock">
                          <div class="d-flex align-items-center justify-content-center flex-column"
                            style="width: 123px; height: 46px;" (click)="currentItem = sign; openModalXL(selectSign);">
                            <p class="m-0">SELECIONAR</p>
                          </div>
                        </button>
                      </div>

                    </ng-container>
                    <!-- Sinalização Com Modal -->
                  </div>
                </div>
              </form>

              <div class="d-flex align-items-center justify-content-between mb-3 dimension-container">
                <h3>
                  Lista de Sinalizações do Item de Projeto
                </h3>
                <button class="btn-popup button-sign p-0">
                  <div class="d-flex align-items-center justify-content-center flex-column"
                    style="width: 123px; height: 46px;" (click)="currentItem = sign; openModalXL(listActivieSigns);">
                    <p class="m-0">ABRIR LISTA</p>
                  </div>
                </button>
              </div>

              <div class="col d-flex flex-column px-4 dimension-container">
                <p class="text-uppercase text-muted mb-2">Dimensão Unitária da Sinalização</p>
                <div class="row mx-0 d-flex align-items-center">
                  <app-dimension-information style="margin-top: -16px;" class="mb-2 w-100"
                    [signType]="signalingTypeEnum.Vertical" [dimensionInformationState]="{
                dimensionType: sign?.dimensionType,
                dimension: sign?.dimension,
                dimensionX: sign?.dimensionX,
                dimensionY: sign?.dimensionY,
                diameter: sign?.diameter,
                sideLength: sign?.sideLength
              }" [indexTraffic]="indexSign" [displayDirection]="'row'" [idSign]="sign.id"
                    [signCode]="sign?.verticalCode" [verticalType]="sign?.verticalType" [switchId]="indexOfSign"
                    (dimensionInformationEmmitter)="handleDimensionInformation($event, sign);emitValidation(true)">
                  </app-dimension-information>
                </div>
              </div>
              <div class="mt-3 mb-4">
                <complement-information-component class="mb-2 w-100" [displayDirection]="'row'" [switchId]="indexOfSign"
                  [complementInformationState]="{type: sign?.complementInformationType,
            value: sign?.complementInformation,
            enableComplementInformation: sign?.complementInformationType ? true : false}"
                  (complementInformationEmmitter)="handleComplementInformation($event, sign)"
                  [verticalType]="sign?.verticalType">
                </complement-information-component>
              </div>
              <div class="col d-flex flex-column px-4 dimension-container">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" [name]="'enableRetroreflection'+indexOfSign"
                    [id]="'enableRetroreflection'+indexOfSign"
                    (change)="!sign['retroSwitch'] ? sign['retroSwitch'] = true : sign['retroSwitch'] = !sign['retroSwitch']; emitValidation()"
                    [checked]="sign.maximumRetroreflectionValue || sign.minimumRetroreflectionValue">
                  <label class="custom-control-label"
                    [for]="'enableRetroreflection'+indexOfSign">Retrorrefletorização</label>
                </div>
                <div class="row mx-0 my-2"
                  *ngIf="sign['retroSwitch'] || sign.maximumRetroreflectionValue || sign.minimumRetroreflectionValue">
                  <div class="form-group col pl-0">
                    <label for="maximum">Valor Desejado (mcd Lux/m²)</label>
                    <input type="number" (change)="emitValidation()" class="form-control"
                      placeholder="Insira o valor desejado" [(ngModel)]="sign.maximumRetroreflectionValue"
                      name="maxValue">
                  </div>
                  <div class="form-group col pr-0">
                    <label for="minimum">Valor Mínimo (mcd Lux/m²)</label>
                    <input type="number" (change)="emitValidation()" class="form-control"
                      placeholder="Insira o valor desejado" [(ngModel)]="sign.minimumRetroreflectionValue"
                      name="minValue">
                  </div>
                </div>
              </div>
              <div class="row mx-0 my-2">
                <div class="col-4 px-0">
                  <div class="px-0 form-group">
                    <label for="legend">Determine o tempo de garantia*</label>
                    <div class="row d-flex align-items-center justify-content-between mx-0">
                      <div class="position-relative w-100">
                        <input bsDatepicker class="form-control" #wDate required
                          [ngModel]="dateService.TransformDate(sign,'get')"
                          [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                          (bsValueChange)="dateService.TransformDate(sign,'set',wDate.value);setWarranty($event, sign);emitValidation()">
                        <i class="fa-solid fa-calendar position-absolute" (click)="$event"
                          style="right: 5%; top: 35%; cursor:text"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="align-items-center d-flex col px-0">
                  <div *ngIf="true" class="d-flex justify-content-between flex-column col-4">
                    <a style="font-size: 20px; color:var(--greenmix-light); pointer-events: none;"
                      [ngClass]="{'text-danger': sign['active'] > 1}">
                      {{!sign['warrantyTextCurrent'] || sign['warrantyTextCurrent'] == 0 ? 'Sem período':
                      sign['warrantyTextCurrent']}}</a>
                    <a [ngClass]="{'text-danger': sign['active'] > 1}"
                      style="color:var(--red-base); font-weight: 500; font-size: 14px; color:var(--greenmix-light); pointer-events: none; white-space: nowrap;">
                      {{sign.warranty ? (sign.warranty | date:'dd/MM/yyyy') : 'Escolha a data de expiração'}}</a>
                  </div>
                  <div class="col px-0">
                    <progressbar *ngIf="sign['active'] == 1" class="mx-4"
                      style="border-radius: 8px; height: 8px; width: 100%" [value]="(sign['progress'])*100"
                      type="success">
                    </progressbar>
                    <progressbar *ngIf="sign['active'] > 1" class="mx-4"
                      style="border-radius: 8px; height: 8px; width: 100%;" [value]="(sign['progress'])*100"
                      type="danger">
                    </progressbar>
                  </div>
                  <div class="d-flex justify-content-between flex-column align-items-end col px-0">
                    <a *ngIf="sign['warrantyTextCurrent'] !== 0"
                      style="font-size: 20px; color:var(--gray-light); pointer-events: none;">{{sign.warranty
                      ? (sign.warranty | date:'dd/MM/yyyy') : 'Sem Período'}}</a>
                    <a *ngIf="sign['warrantyTextCurrent'] == 0"
                      style="color:var(--red-base); font-weight: 500; font-size: 16px; pointer-events: none;"><i
                        class="fa-solid fa-triangle-exclamation"
                        style="margin-right: 8px; font-size: 20px;"></i>Garantia
                      Expirada</a>
                    <h4 class="mt-2" style="color:var(--gray-light)" *ngIf="sign['warrantyTextCurrent'] !== 0">
                      Sem
                      última edição</h4>
                  </div>
                </div>
              </div>
              <div class="row  px-4 justify-content-between align-items-center  my-2 py-2">
                <div class="col px-0">
                  <div>
                    <label for="signQuant" class="text-muted">Qnt. de Sinalizações</label>
                    <p>{{sign?.quantity || 'Não informado'}}</p>
                  </div>
                </div>
                <div class="col px-0">
                  <label for="roadSignSubstrate" class="text-muted">Substrato da Placa</label>
                  <p>{{sign.contractVerticalItem?.roadSignSubstrate?.name ?
                    (sign.contractVerticalItem?.roadSignSubstrate.name | enumText:'RoadSignSubstrate') : 'Não informado'
                    }}
                  </p>
                </div>
                <div class="col px-0">
                  <label for="roadSignFilm" class="text-muted">Tipo de Película</label>
                  <p>{{sign?.contractVerticalItem?.roadSignFilmItems[0]?.roadSignFilm?.name ?
                    sign.contractVerticalItem?.roadSignFilmItems[0]?.roadSignFilm?.name : 'Não informado' }}</p>
                </div>
                <div class="col px-0">
                  <label for="dimension" class="text-muted">Vr. Unt. da Sinalização</label>
                  <p class="text-success">{{sign?.contractVerticalItem ? (sign.dimension *
                    sign?.contractVerticalItem?.unitValue | currency:'BRL':'R$') : ('R$0,00')}}</p>
                </div>
                <div class="col px-0">
                  <label for="unitValue" class="text-muted">Valor do Item</label>
                  <p>{{ sign?.contractVerticalItem ? ((sign.contractVerticalItem.unitValue |
                    currency:'BRL':'R$')+'/'+(sign.contractVerticalItem.unit)) : ('R$0,00/--')}}</p>
                </div>
                <div class="col px-0">
                  <label for="totalValue" class="text-muted">Valor Total</label>
                  <p class="text-success">{{sign.contractVerticalItem ? (sign.quantity * sign.dimension *
                    sign.contractVerticalItem.unitValue | currency:'BRL':'R$') : ('R$0,00')}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Formulário da Sinalização -->


          <!-- Collapse -->
          <div class="collapse-button" (click)="openAccordionGroup(sign)">
            <i class="fa-regular fa-lg" [ngClass]="sign?.isAccOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
          </div>
          <!-- Collapse -->

        </div>
        <div class="col-auto px-0 pl-4" *ngIf="!isStock">
          <button class="btn btn-icon btn-icon-alt" (click)="removeActivity(indexOfSign)">
            <i class="fa-regular fa-xmark fa-lg text-danger"></i>
          </button>
        </div>
      </div>

    </accordion-group>
  </accordion>
</ng-container>

<!-- Template Modal Selecionar Sinalização Filtro-->
<ng-template #selectSign>
  <app-sign-list-modal [signType]="signalingTypeEnum.Vertical" [signModalSelect]="this.currentItem.verticalCode"
    (shareSelectSign)="selectSignModal($event)" (shareCloseModal)="closeModal()">
  </app-sign-list-modal>
</ng-template>
<!-- Template Modal Selecionar Sinalização Filtro-->

<ng-template #listActivieSigns>

  <app-table-map-activitie-project [signType]="signalingTypeEnum.Vertical" [currentActivitieSign]="currentItem"
    (emitActivitieSign)="receiveActivitie($event)" (emitCloseModal)="closeModal()">
  </app-table-map-activitie-project>

</ng-template>
