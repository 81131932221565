import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeviceSignalingEnum, HorizontalSignalingEnum, VerticalSignalingEnum } from 'src/enumerators';
import { ProjectImportFile } from 'src/models/project';

@Component({
  selector: 'app-project-item-import',
  templateUrl: './project-item-import.component.html',
  styleUrls: ['./project-item-import.component.css']
})
export class ProjectItemImportComponent implements OnInit {

  @Input() itemList;
  @Input() itemType;
  @Input() resultList: ProjectImportFile;
  @Output() handleImport = new EventEmitter<any>;
  @Output() handleItemType = new EventEmitter<any>;

  enumDeviceValues = Object.values(DeviceSignalingEnum);
  enumVerticalValues = Object.values(VerticalSignalingEnum);
  enumHorizontalValues = Object.values(HorizontalSignalingEnum);

  currentSign;
  currentType;

  signTypeUpper;
  enumType = {
    vertical: 'Vertical',
    support: 'Suporte',
    horizontal: 'Horizontal',
    device: 'Dispositivos',
  }
  modalsRef?: Array<BsModalRef> = [];

  constructor(
    private _modalService: BsModalService,

  ) { }

  ngOnInit(): void {
    this.signTypeUpper = this.itemType.toUpperCase()

    this.resultList[this.itemType].forEach(element => {
      let code;
      if (this.itemType == 'vertical') {
        code = this.enumVerticalValues.find(value => value.startsWith(element.type));
        if (element?.contractItems?.length) {
          element.contractItemId = element.contractItems[0].id;
          element.contractItem = element.contractItems[0];
          delete element.contractItems;
        }
      }

      else if (this.itemType == 'horizontal') {
        code = this.enumHorizontalValues.find(value => value.startsWith(element.type));
        if (element?.contractItems?.length) {
          element.contractItemId = element.contractItems[0].id;
          element.contractItem = element.contractItems[0];
          delete element.contractItems;
        }
      }

      else if (this.itemType == 'device') {
        code = this.enumDeviceValues.find(value => value.startsWith(element.type));
        if (element?.contractItems?.length) {
          element.contractItemId = element.contractItems[0].id;
          element.contractItem = element.contractItems[0];
          delete element.contractItems;
        }
      }
      else {
        if (element.type != 'Outro')
          code = element.type;
        else element.check = true;
      }

      element.type = code;
    });
  }

  contractItemName(item) {
    return item['itemNumber'] + ' - ' + item.name;
  }

  emitEvent() {
    this.handleImport.emit(this.resultList);
  }

  handleContractItem(value, i) {
    this.resultList[this.itemType][i].contractItem = this.resultList[this.itemType][i].contractItems.find(item => item.id == value);
  }

  // Fecha o último modal aberto
  closeModal() {
    const last: any = this.modalsRef.length - 1;
    this.modalsRef[last].hide();
    this.modalsRef.pop();
  }

  // Abre MODAL
  openModal(template: TemplateRef<any>, size = 'modal-lg') {
    const modalRefAux: BsModalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered ' + size,
      backdrop: 'static',
    });
    this.modalsRef.push(modalRefAux);
  }

  // Preserva ordem do ngFor | keyvalue
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  changeCodeProject(sign, template: TemplateRef<any>) {
    this.currentSign = sign;
    this.openModal(template);
  }

  handleCode(code){
    this.currentSign.type = code;
  }
}
