<div class="modal-header">
  <h3 class="modal-title">Lista de Sinalizações do Item do Projeto</h3>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <i aria-hidden="true" class="fas fa-xmark"></i>
  </button>
</div>
<div class="modal-body d-flex align-items-center" style="gap: 24px">
  <div class="scroll-table">
    <table class="table table-sm" style="border: 0">
      <thead>
        <tr>
          <th>
            Sinalização
          </th>
          <th>
            Código/Referência
          </th>
          <th>
            Latitude/Longitude
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of currentActivitieSign?.activitiesList;index as IndexActivitie">
          <th class="d-flex align-items-center" style="gap: 8px">
            <img
              [src]="item.signCode ? (item.signCode| enumSvg: 'Signaling': signType) : 'assets/icons/v-sign-task-icon.svg'"
              alt="" style="object-fit: contain;" height="48px" width="48px"
              [ngClass]="{'sign-cam-40': item?.signCode == verticalSignEnum?.ViaFiscalizadaPorCameras}">
            <div class="point-AB" *ngIf="signType == signalingTypeEnum.Device">
              {{IndexActivitie ? 'B' : 'A'}}
            </div>
          </th>
          <th>
            <input type="text" class="form-control" placeholder="Código de Referência" [(ngModel)]="item.code" />
          </th>
          <th>
            <div>
              <input type="text" class="form-control" placeholder="Insira o valor" [(ngModel)]="item.latlngForm"
                (change)="changeLatLngForm(item, signType, $event.target.value, IndexActivitie)" />
            </div>
          </th>
          <th>
            <button (click)="enableMarkerPlacement(item, IndexActivitie)"
              class="btn btn-primary d-flex align-items-center justify-content-center" style="width: 0;">
              <i class="fas fa-location-dot fa-lg m-0"></i>
            </button>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
  <div id="activitie-sign-map">

  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary mr-2" (click)="closeModal()">
    <h4 style="font-weight: 600">Fechar</h4>
  </button>
</div>
